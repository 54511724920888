/**
 * Classe WCManager
 * Centralise la gestion des WebControls de la page
 */

var WCManager = new Class({
    // Constructeur
    initialize : function() {
        this.controls = new Hash();    	// Tableau des controles
        this.eventsDisabled = false;    // Si true, alors les événements de tous les controles sont désactivés
        this.delayedMethods = {};
    },

    // Ajout d'un controle
    addControl : function(oCtrl, oElem) {
        if (!oCtrl) return false;

        // Ajoute au tableau
        this.controls.set(oCtrl.id, oCtrl);

        // Initialise
        if (oCtrl.init) oCtrl.init();

        // Attache les événements
        if (oCtrl.addEvents) oCtrl.addEvents();

        // Place un flag pour ne pas initialiser 2 fois
        if (oElem) oElem.added = 1;
    },

    // Ajoute un WebUpload
    addWebUpload : function(oElem, strID, iMaxFileSize, strViewID, bAjax) {
        // On regarde si le traitement n'a pas déjà été fait
        if (oElem.added) return true;

        // Crée un nouveau contrôle
        var oCtrl = new WebUpload(strID);

        // Positionne les options
        oCtrl.maxFileSize = iMaxFileSize;
        oCtrl.viewID = strViewID;
        oCtrl.ajax = bAjax;

        // Ajout du contrôle au gestionnaire
        this.addControl(oCtrl, oElem);
    },

    execMethod : function(oElem, method){
        var oCtrl = this.controls.get(oElem);
        if(oCtrl){
            this.delayedMethods[oCtrl.id] = this.delayedMethods[oCtrl.id] || [];
            this.delayedMethods[oCtrl.id] = method;
        }
    },
    
    // Add WebSelector
    addWebSelector : function(oElem, strID) {

        // Check if already added
        if (oElem.added) return true;

        // Create new control
        var oCtrl = new WebSelector(strID);

        // Execute and clear delayed method
        var method = this.delayedMethods[oCtrl.id];
        if(method && method in oCtrl) {
            oCtrl[method]();
            this.delayedMethods[oCtrl.id] = null;
        }

        this.addControl(oCtrl, oElem);
    },

    // Masquage de tous les contrôles
    hideAll: function() {
       $each(this.controls, function(oElem, iIndex) {
           if (oElem.hide) oElem.hide();
       });
    }
  
});

/**
 * IMplémentation du Desing Pattern Singleton
 */
WCManager.instance = null;

WCManager.getInstance = function() {
    if (WCManager.instance == null) {
        WCManager.instance = new WCManager();
    }
    return WCManager.instance;
}

/**
 * Alias statique de addControl
 */
WCManager.addControl = function(oCtrl, oElem) {
    return WCManager.getInstance().addControl(oCtrl, oElem);
}
