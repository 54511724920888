/**
 * Classe Utils
 * Fonctions utilitaires diverses
 * Attention, tout n'est pas encore intégré à la classe
 * On a donc un mélange de méthodes statiques et de fonctions
 */
var PsUtils = new Class({
});

// Renvoie la valeur "intéressante" d'un champ de formulaire
// De plus encode en base64 pour éviter les problèmes de transfert
PsUtils.getFieldValue = function (strName) {
    var strResult = '';
    var aResult = [];   // Certains input (les checkbox) renvoient un tableau
    var aControls = document.getElementsByName(strName);

    $each(aControls, function (oInput, iCtrl) {
        $(oInput);

        // Selon le type de champ, la valeur n'est pas au même niveau
        if (oInput.get('tag') == 'select' || oInput.get('tag') == 'SELECT') {
            if (oInput.selectedIndex >= 0) {
                if (strResult) strResult += ',';
                strResult += oInput.options[oInput.selectedIndex].value;
            }
        }
        else if (oInput.get('type') == 'radio') {
            if (oInput.checked) {
                strResult += oInput.value;
            }
        }
        else if (oInput.get('type') == 'checkbox') {
            if (oInput.checked) {
                aResult.push('base64:' + Base64.encode(oInput.value));
            }
        }
        else {
            if (strResult) strResult += ',';
            strResult = oInput.value;
        }
    });

    // Si on a un tableau de valeurs, ça prend le dessus
    if (aResult.length > 0) return aResult;

    // Retourne la valeur encodée en base64
    if (strResult) {
        return 'base64:' + Base64.encode(strResult);
    }
    else {
        return '';
    }
}

/**
 * Inject HTML contents into an element and evaluate scripts it contains
 * @param elementId The id of the target element
 * @param htmlContents The html to inject as a string
 */
PsUtils.injectHtml = function (elementId, htmlContents) {
    const element = document.getElementById(elementId)
    if (!element) {
        return;
    }

    // KLUDGE: We need this to have tinyMCE behave correctly
    // My understanding is that when we overwrite a div with a newer version of its contents
    // and the div contains tinyMCE elements, then tinyMCE keeps a reference to the old element
    // so it's not able to initialize correctly with the new element. I believe this is because it is based
    // on the html ID, not a DOM reference.
    // This code deletes the old tinyMCE so the new one can be created.
    element.querySelectorAll('[data-htmleditor=tinymce]').forEach(function (elt) {
        tinymce.get(elt.id).remove()
    })

    element.innerHTML = htmlContents

    // KLUDGE: need to initialize some components manually...
    element.querySelectorAll('[data-toggle="luceo-pagination"]').forEach(function (elt) {
        if (!elt.getAttribute('data-pagination')) {
            // KLUDGE: it should be possible to remove this dependency to jQuery without too much effort
            // but currently Luceo.Pagination expects a jQuery instance
            const pagination = new Luceo.Pagination(jQuery(elt))
            if (pagination) {
                elt.setAttribute('data-pagination', pagination)
            }
        }
    })
    element.querySelectorAll('select').forEach(function (elt) {
        // we still have this dependency on jQuery
        // remoing it means redesigning the widget, which will probably move the need for this hack entirely
        jQuery(elt).select()
    })

    element.querySelectorAll('script').forEach(function (script) {
        // KLUDGE: Of course we should not do that...
        // but we're stuck with it for now. This will hopefully disapear if we complete the new UI project
        eval(script.innerHTML)
    })
}

/**
 * Ajoute un contenu HTML en gérant les différents formats de transferts
 */
PsUtils.setHTML = function (iIDElement, strHTML, oOptions) {
    if (!oOptions) oOptions = {};

    if (oOptions.encoded == 'base64') {
        strHTML = Base64.decode(strHTML);
    }
    else if (oOptions.encoded == 'json') {
        strHTML = JSON.decode(strHTML);
    }

    // Colle le contenu HTML
    $(iIDElement).set('html', strHTML);

    // KLUDGE
    jQuery(document).ready(function () {
        jQuery('[data-toggle="luceo-pagination"]').each(function () {
            if (jQuery(this).data('pagination') == undefined) {
                var pagination = new Luceo.Pagination(jQuery(this));
                if (pagination) {
                    jQuery(this).data('pagination', pagination);
                }
            }
        });
        jQuery('select').each(function () {
            jQuery(this).select();
        })
    });
}

/**
 * Ajoute un contenu HTML à un élément et évalue les scripts
 * APRES l'insertion de l'html pour permettre la manipulation
 * Du code nouvellement inséré
 */
PsUtils.setHTMLAndEval = function (iIDElement, strHTML, oOptions) {
    jQuery(iIDElement).find('[data-htmleditor=tinymce]').each(function (i, j) {
        var id = '#' + jQuery(j).attr('id');
        tinymce.remove(id);
    });

    PsUtils.setHTML(iIDElement, strHTML, oOptions);

    // Détecte les balises script dans l'élement
    PsUtils.evalScripts(iIDElement);
}

/**
 * Evalue les scripts contenus dans un mélange HTML/scripts
 */
PsUtils.evalScripts = function (iIDElement) {
    // Détecte les balises script dans l'élement
    var aScripts = $(iIDElement).getElements('script', 'SCRIPT');
    $each(aScripts, function (oScript, index) {
        eval(oScript.innerHTML);
    });
}


/**
 * Retourne le document d'une iframe
 */
PsUtils.getIframeDocument = function (oIframe) {
    var oDoc = oIframe.contentWindow || oIframe.contentDocument;
    if (oDoc.document) {
        oDoc = oDoc.document;
    }
    return oDoc;
}

/**
 * Permet de gérer du code a exécuter une seule fois
 * Se base sur un cookie. Si le cookie existe, renvoie true et le supprime
 * S'il n'existe pas renvoie false
 */
PsUtils.executeOnce = function (strCookieName) {
    if (Cookie.read(strCookieName)) {
        Cookie.dispose(strCookieName);
        return true;
    }
    else {
        return false;
    }
}

/**
 * Ajoute (ou modifie) un paramètre dans la vue spécifiée
 * Comme on ne sait pas accéder directement aux vrais paramètres à cause du gzip, on
 * rajoute dynamiquement un nouveau champ caché __ParamsJS
 * qui sera prioritaire dans la récupération des données côté serveur
 */
PsUtils.setViewParam = function (strViewID, strParam, strValue) {

    // Ajoute un input hidden après la champ caché params
    var oParamsJS = $(strViewID + '__ParamsJS');
    var aValues = new Hash();

    if (!oParamsJS) {
        oParamsJS = new Element('input', {
            type: 'hidden',
            id: strViewID + '__ParamsJS',
            name: strViewID + '__ParamsJS'
        });
        oParamsJS.inject($(strViewID + '__Params'), 'after');
    }
    else {
        aValues = new Hash(JSON.decode(Base64.decode($(strViewID + '__ParamsJS').value)));
    }
    aValues.set(strParam, strValue);
    $(strViewID + '__ParamsJS').value = Base64.encode(JSON.encode(aValues));
}

/**
 * Méthode de centrage d'une div prenant en compte le bug IE de mootools
 */
PsUtils.centerElement = function (oElem) {
    // Centré sur la page
    $(oElem).position();

    if (Browser.ie) {
        /**
         * On recalcule pour IE car la méthode position() de mootools ne fonctionne plus correctement en 1.3.2
         * Note: il faut quand même conserver l'appel à position() ci dessus pour les transformations (position absolue, etc)
         */
        var oWindowSize = window.getSize();
        var oElementSize = oElem.getSize();
        var oScroll = window.getScroll();

        var x = oScroll.x + (oWindowSize.x / 2) - (oElementSize.x / 2);
        var y = oScroll.y + (oWindowSize.y / 2) - (oElementSize.y / 2);
        if (x < 0) x = 0;
        if (y < 0) y = 0;

        oElem.setPosition({
            x: x,
            y: y
        });
    }
}

/**
 * Chargement de la div d'attente (wait)
 */
PsUtils.startLoading = function (waitLoaderLabel) {
    if (document.getElementById('wait')) {
        document.getElementById('wait').style.display = 'block';

        if (!waitLoaderLabel) {
            waitLoaderLabel = PsLocal.TXT_WAIT_LOADER_LABEL_DEFAULT;
        }

        document.getElementById('waitLabel').innerHTML = waitLoaderLabel;
    }
}

/**
 * fermeture de la div d'attente (wait)
 */
PsUtils.stopLoading = function () {
    if (document.getElementById('wait')) {
        document.getElementById('wait').style.display = 'none';
    }
}
