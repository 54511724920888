/**
 * Support javascript du WebTextBoxList
 */
var WebTextBoxList = new Class({
	initialize: function(strID, oOptions, oData) {
		this.id = strID;
		this.options = oOptions;    // options
		this.data = oData;  		// Informations sur la source de données (pour autocomplétion)
		this.tbl = null;   			// Objet TextBoxList attaché
		this.acpl = null;   		// Objet Autocompleter attaché

        if ($(strID).initialized) return;

		// Stocke tout de suite la valeur initiale
		var strCtrlValue = $(strID).value;
		values = strCtrlValue.split(',');

		// Création
		if (this.options.autocomplete) {
			this.tbl = new TextboxList(this.id, {prefix: this.options.prefix, unique: true, plugins: {autocomplete:{}}});
			this.acpl = this.tbl.plugins.autocomplete;

			var tbl = this.tbl;
			tbl.list.getChildren().each(function (el) {
				child = tbl.getBit(el);
				childValue = child.getValue();
				var iValuesCount = values.length;
				for (var j = 0; j < iValuesCount; j++) {
					if (childValue[1] == values[j] && childValue[1] != "") {
						child.remove();
					}
				}
			});

            if (this.options.onlyFromValues) this.acpl.options.onlyFromValues = true;
            this.acpl.options.placeholder = PsLocal.TXT_WEBTEXTBOXLIST_PLACEHOLDER;
            if (Luceo.jsProperties && Luceo.jsProperties.upliftedUI) {
                if (this.options.max && this.options.max == 1) {
                    this.acpl.options.placeholder = this.acpl.options.placeholder + '<br/><div class="helper-text-small">' + PsLocal.TXT_WEBTEXTBOXLIST_PLACEHOLDER_MAXOPTION_HELPER + '</div>';
                }
            }

            if (this.data.mode == 'local') {
				this.acpl.setValues(this.data.values);
			}
			else if (this.data.mode == 'remote') {
			    // On doit réaliser l'équivalent d'un psAjax::submitView mais
			    // en restant dans ce que permet de faire le composant js
			    // On va donc devoir tout faire à la main
			    // Récupère les informations sur la vue en cours
			    this.oViewInfo = PsAjax.loadViewInfo(this.data.viewId);
			    this.oViewInfo.id = this.data.viewId;

				var oThis = this;
				this.acpl.setOptions({
					minLength: this.options.minLength,
					queryRemote: true,
					maxResults: this.options.maxResults,
					remote: {
						url: '/' + _psfc + '.php' + '/' + this.oViewInfo.url,
						extraParams: function() {
							return oThis.loadViewState();
						}
					}
				});
			}
			// Valeurs sélectionnées
			if (this.data.selected) {
			    var iCount = this.data.selected.length;
			    for (var i = 0; i < iCount; i++) {
			        var strHTMLValue = this.data.selected[i][1];
			        if (this.data.selected[i][2]) strHTMLValue = this.data.selected[i][2];

					this.tbl.add(this.data.selected[i][1], this.data.selected[i][0], strHTMLValue);
			    }
			}
		}
		else {
			this.tbl = new TextboxList(this.id, {prefix: this.options.prefix, unique: true});
		}

		// Nombre maximal d'éléments
		if (this.options.max) this.tbl.setOptions({max: this.options.max});



		// Evénements
		var oThis = this;
		if (this.options.apb) {
			this.tbl.addEvent('bitBoxAdd', function(bit) {
	            ta(oThis.data.controlId);
	        });
			this.tbl.addEvent('bitBoxRemove', function(bit) {
	            ta(oThis.data.controlId);
	        });
		}
		else if (this.options.onchange) {
			this.tbl.addEvent('bitBoxAdd', function(bit) {
	            eval(oThis.options.onchange);
	        });
			this.tbl.addEvent('bitBoxRemove', function(bit) {
	            eval(oThis.options.onchange);
	        });
		}

        // Avoid double initialization;
        $(strID).initialized = true;
	},

	/**
	 * Charge le viewstate de la vue pour que l'autocomplétion récupère
	 * le contexte d'exécution
	 */
	loadViewState: function() {
	    var aExtraParams = {};

	    // Ajoute l'action pour l'inclure dans le viewstate
	    this.oViewInfo.action = this.data.viewId + this.data.controlId + '_Autocomplete';

	    // Le paramètre d'action est la recherche
	    this.oViewInfo.actionParam = this.acpl.currentInput.getValue()[1];

	    // Les infos sur la vue sont fournies par PHP
		aExtraParams.viewstate = PsAjax.getViewState(this.oViewInfo);

		aExtraParams.ajax = 'true';
		aExtraParams.viewid = this.data.viewId;
		aExtraParams.layout = this.oViewInfo.layout;

		return aExtraParams;
	}

});
