/**
 * Classe PsWindow
 * Fenêtre javascript standard
 */
var PsWindow = new Class({
	/**
	* Constructeur
	*
	* Contents:
	* - method          Methode pour un chargement Ajax
	* - args            Arguments pour la méthode Ajax
	* - html        	Contenu passé directement
	*
	* Options:
	* - movable:  		Déplacable (true par défaut)
	* - autoHide:   	Masquage automatique: Valeur en millisecondes. Si 0 (défaut) alors pas de masquage
	* - noframe:    	Affichage sans cadre (défaut: false)
	* - showWait:    	Affichage ou non de l'animations d'attente (défaut: true)
	* - attach:  		Elément de rattachement (si positionné par rapport à un élément)
	* - attachpos:    	Positionnement (exemple: ['upperRight','upperLeft'] positionne le coin
	* 					supérieur droit de la div sur le coin supérieur gauche de la div parente
	*               	Prend aussi 'center' pour un positionnement centré sur la page
	* - evalScripts     Evaluation automatique des scripts inclus dans la page (défaut: true)
	* - background		permet d'ajouter un background à la fenêtre
	* - mainClass		permet de modifier la classe css principale
    * - modal			Masque le reste de l'écran
	* - onClose			Evenement appelé sur fermeture de la fenêtre
	*/
	initialize: function(strID, oContents, oOptions) {
		this.id = strID;			// identifiant unique

		// Enregistrement
		PsWindowManager.getInstance().register(this);

		// Initialisation effective de l'objet
	    this.initObject(strID, oContents, oOptions);

		// A la création de l'objet, on crée aussi la DIV elle même
		this.build();

		// Création effective
		if ($('MainForm')) {
			this.html.main.inject($('MainForm'), 'bottom');
		}
		else {
			this.html.main.inject(document.body, 'bottom');
		}

		// Ajoute les événements liés
		this.addEvents();

		// Execute
		this.execute(oContents);
	},

	// Initialisation effective de l'objet
	initObject: function(strID, oContents, oOptions) {
		this.visible = false;		// Visibilité
		this.filled = false;		// Remplie
		this.content = '';			// Contenu
		this.timer = null;			// Timer de suppression

		// options
		this.movable = true;		// Déplacable
	  	this.autoHide = 0;          // Masquage automatique
	  	this.noframe = false;       // Affichage sans cadre
	  	this.showWait = true;       // Affichage d'une image d'attente pendant le chargement
	  	this.attachpos = ['upperRight','upperLeft'];		// Positionnement
	  	this.evalScripts = true;   // Evaluation automatique des scripts
	  	this.background = ''; 		// style du background
	  	this.modal = false;         // Fenêtre modale
	  	this.minWidth = '';         // Largeur minimale
	  	this.mainClass = 'content_ifb is-tooltip';  // Classe css principale
		this.onClose = null;

	 	// Ecrasement des options
	  	if ($defined(oOptions.movable)) this.movable = oOptions.movable;
	  	if ($defined(oOptions.autoHide)) this.autoHide = oOptions.autoHide;
	  	if ($defined(oOptions.noframe)) this.noframe = oOptions.noframe;
	  	if ($defined(oOptions.showWait)) this.showWait = oOptions.showWait;
	  	if ($defined(oOptions.evalScripts)) this.evalScripts = oOptions.evalScripts;
	  	if ($defined(oOptions.background)) this.background = oOptions.background;
	  	if ($defined(oOptions.modal)) this.modal = oOptions.modal;
    	if ($defined(oOptions.delay)) this.delay = oOptions.delay;
    	if ($defined(oOptions.attachpos)) this.attachpos = oOptions.attachpos;
    	if ($defined(oOptions.minWidth)) this.minWidth = oOptions.minWidth;
    	if ($defined(oOptions.mainClass)) this.mainClass = oOptions.mainClass;
		if ($defined(oOptions.onClose)) this.onClose = oOptions.onClose;
        if ($defined(oOptions.iframeShow)) this.iframeShow = oOptions.iframeShow;

		// Parties composant la fenêtre
		this.html = {
		    main: null, 		// Div principale
		    window: null,		// Div contenant la structure de la fenêtre elle-même
		    minwidth: null,		// Div permettant de fixer la largeur
		    top: null,			// Section haute (contenant la barre)
		    middle: null,		// Section principale
		    contents: null,		// Zone de contenu
		    bottom: null,		// Section basse (footer)
		    topbar: null,       // Barre du haut
		    handle: null,       // "Poignée" pour le drag&drop
		    closeAnchor: null,  // Anchor de fermeture
		    closeImg: null,  	// Icône de fermeture
		    background: null,   // Background

		    parent: oOptions.attach // Parent html (permet le "rattachement" de la div)
		};

		// Elements HTML masqués lors de l'affichage (IFRAME principalement)
		this.hiddenElements = [];
	},

	// Construit le rendu HTML
	build: function() {
		var strHTMLID = this.id;

		this.html.main = new Element('div', {
		 'id': 		strHTMLID + '_div',
		 'class': 	this.mainClass,
		 'styles': {
		   'opacity': 0
		   }
		});

		if (this.background) {
			this.html.main.setStyle('background', this.background);
		}

		this.html.window = new Element('div', {
		 'class': 	'ifb'
		});
		this.html.window.inject(this.html.main, 'bottom');


		this.html.minwidth = new Element('div', {
		 'class': 	'minwidth_ifb'
		});
		if (this.minWidth) this.html.minwidth.setStyle('width', this.minWidth);
		this.html.minwidth.inject(this.html.window, 'bottom');

		// Section haute
		this.html.top = new Element('div', {
			'class':    'top_ifb'
		});
		this.html.top.inject(this.html.window, 'bottom');

		// Barre du haut
		this.html.topbar = new Element('div', {
			'class':    'interne'
		});
		this.html.topbar.inject(this.html.top, 'bottom');

		if (! this.noframe) {
			// Handle
			this.html.handle = new Element('div', {
				'class':    'handle'
			});
			this.html.handle.inject(this.html.topbar, 'top');

			// Close
			this.html.closeAnchor = new Element('span', {
			    //'href': '#'
			});
			this.html.closeAnchor.inject(this.html.handle, 'bottom');

			// Image close
			this.html.closeImg = new Element('i', {
			 'class': 	'fa fa-times',
			 'alt': 	'close'
			});
			this.html.closeImg.inject(this.html.closeAnchor, 'bottom');
		}

		// Contenu
		this.html.middle = new Element('div', {
		 'class': 	'middle_ifb'
		});
		this.html.middle.inject(this.html.window, 'bottom');

		this.html.contents = new Element('div', {
		 'class': 	'interne'
		});
		this.html.contents.inject(this.html.middle, 'bottom');

		// Bas
		this.html.bottom = new Element('div', {
			'class':    'btm_ifb'
		});
		this.html.bottom.inject(this.html.window, 'bottom');

		// Cleaner
		var cleaner = new Element('br', {
		 'class': 	'cleaner'
		});
		cleaner.inject(this.html.window, 'bottom');

		new Element('div', {
			'class':    'interne'
		}).inject(this.html.bottom, 'bottom');

		// Fond grisé pour fenêtres modales
		if (this.modal) {
			this.html.background = new Element('div', {
				'class':    'bg_dark'
			});
			this.html.background.inject(this.html.window, 'after');
		}

		// Image d'attente
		if (this.showWait) {
			this.wait();
		}
	},

	// Affichage de l'icône d'attente
	wait: function() {
	},

	// Execution
	execute: function(oContents) {

		// Ordre d'exécution diffère selon le type de contenu (et quelques options)
		if (! this.filled) {
		    if (oContents.method || oContents.loadView) {
				// Appel ajax asynchrone
				var bStripScripts = true;
				if ($defined(oContents.stripScripts)) bStripScripts = oContents.stripScripts;

				if (! oContents.HTTPmethod) oContents.HTTPmethod = 'get';
				if (! oContents.controler) oContents.controler = _psfc;

                if ($defined(oContents.loadView)) {
                	PsAjax.loadView({
                		HTTPmethod: oContents.HTTPmethod,
						controler: oContents.controler,
						id: oContents.viewId,
						url: oContents.viewUrl,
						queryString: oContents.viewQueryString,
						layout: 'windowlayout',
						ajaxCache: oContents.ajaxCache
					}, {targetObject: this, stripScripts: bStripScripts});
                }
                else {
                	PsAjax.remoteMethod(oContents.method, oContents.args, {targetObject: this, stripScripts: bStripScripts});
                }

				// Note tout de suite comme rempli pour éviter appels ajax multiples
				this.filled = true;

				// Positionne
				this.move();

				if (this.showWait) {
					// Affiche
					//this.show();
				}
		    }
			else {
			    var strContents = '';
			    if (oContents.base64 == true) {
			    	strContents = Base64.decode(oContents.html);
			    }
			    else {
			    	strContents = oContents.html;
			    }

				this.fill(strContents);

				// Déplace
				this.move();

				// Affiche
				this.show();
			}
		}
	},


	// Remplit à partir d'un contenu
	fill: function(strContents) {
	    /**
	     * Gère soit du contenu brut, soit du contenu encodé base64
	     */
	    var oOptions = {};
		if (strContents.substr(0, 7) == 'base64:') {
			strContents = strContents.substr(7);
			oOptions.encoded = 'base64';
		}

		if (this.evalScripts == true) {
		    // Colle et évalue les scripts
			PsUtils.setHTMLAndEval(this.html.contents, strContents, oOptions);
		}
		else {
			// Crée un nouvel élément HTML avec le contenu
			PsUtils.setHTML(this.html.contents, strContents, oOptions);
		}
		this.filled = true;
	},

	// Déplacement réél
	move: function() {
		if (!this.html.parent) {
		    // Centré sur la page
		    PsUtils.centerElement(this.html.main);
		}
		else {
		    /**
		     * Calcule le positionnement maximum possible (= taille de l'écran - hauteur de la div
		     * On ne veut en effet pas que le "tip" descende au delà de la taille du document
		     */
		    var iMax = $(document.body).getSize().y - this.html.main.getSize().y + window.getScroll().y;

			$(this.html.main).position({
			    relativeTo: $(this.html.parent),
			    position: this.attachpos[1],
			    edge: this.attachpos[0],
			    maximum: {y:iMax},
			    minimum: {x:0,y:0}
			});

			// Refait un deuxième positionnement car le premier a pu modifier la largeur
			$(this.html.main).position({
			    relativeTo: $(this.html.parent),
			    position: this.attachpos[1],
			    edge: this.attachpos[0],
			    maximum: {y:iMax},
			    minimum: {x:0,y:0}
			});

		}
	},

	// Rend une fenêtre visible
	show: function() {
        //if not ff jtbd-workflow-service-integration
        if (!this.iframeShow) {
                /**
             * We must hide the elements that do not want to go in the background, mainly the IFRAMES
             */
            this.hiddenElements = [];

            var oThis = this;

            // Elements belonging to the window and therefore should not be hidden
            var aWindowElements = this.html.main.getElements('iframe');

		    // Mask the annoying elements except those of the window
            $$('iframe').each(function (elem) {
                if (aWindowElements.indexOf(elem) == -1) {
                    if ((elem.src).indexOf('https://ats-messenger') > -1
                        && (elem.id).indexOf('frame-') > -1
                    ) {
                        return;
                    }

                    oThis.hiddenElements.push(elem);
                    elem.setStyle('display', 'none');
                }
            });
        }

        this.visible = true;

		new Fx.Tween(this.html.main, {
		    //duration: 'short',
		    property: 'opacity'
		}).start(40, 100);

        if (this.modal) {
        	Luceo.Form.disableSubmitButton();
        }
		//this.html.main.fade('in');
	},

	// Rend une infobulle invisible
	hide: function() {
		this.visible = false;
		this.html.main.fade('hide');

		// Déplace hors de l'écran
		this.html.main.style.left = -1000;
		this.html.main.style.top = -1000;

        //if not ff jtbd-workflow-service-integration
        if (!this.iframeShow) {
            // Réaffiche les éléments masqués à l'affichage
            this.hiddenElements.each(function (elem) {
                elem.setStyle('display', 'block');
            });
        }

		// Annule l'éventuel timer
		if (this.timer) clearTimeout(this.timer);

		if (this.onClose) this.onClose();

        // Enable enter key
        if (this.modal) {
            Luceo.Form.enableSubmitButton();
        }
    },

	// Réveille une fenêtre (lors qu'on demande sa réactivation)
	wakeup: function() {
	},

	// Commence un décompte de suppression
	countDown: function() {
		if (this.timer) clearTimeout(this.timer);

		if (this.autoHide > 0) {
			this.timer = setTimeout('var oWindow = PsWindowManager.getInstance().windows[' + this.index + ']; if (oWindow) oWindow.hide()', this.autoHide);
		}
	},

	// Annule le décompte de suppression
	keepAlive: function() {
		if (this.timer) clearTimeout(this.timer);
	},

	// Ajoute les événements
	addEvents: function() {
		var oWindow = this;
		if (this.autoHide) {
			$(this.html.main).addEvent('mouseover', function(e){ oWindow.keepAlive(); });
			$(this.html.main).addEvent('mouseout', function(e){
				/**
				 * Attention, on a un bug sur IE9 et IE8 sur le survol des scrollbars de l'iframe qui déclenche un mouseout
				 * On repère ce cas avec e.relatedTarget qui vaut null (car les scrollbars ne sont pas de vrais éléments html)
				 */
				if (e.relatedTarget) oWindow.countDown();
			});
		}

		// Attache la fermeture sur l'attachement
		if (this.html.parent) {
			$(this.html.parent).addEvent('mouseout', function(event){ oWindow.countDown(); });
		}

		// Bouton de fermeture
		if (this.html.closeAnchor) {
			$(this.html.closeAnchor).addEvent('click', function(event){ oWindow.hide(); return false; });
		}

		// Active le drag & drop
		if (this.movable && this.html.handle) {
			new Drag(this.html.main, {
			    snap: 15,
			    handle: this.html.handle,
				onSnap: function(el){
					el.setStyle('opacity','.8');
			    },
			    onComplete: function(el){
			    	el.setStyle('opacity','1');
			    }
			});
		}
	},

	/**
	 * Evénement appelé sur chargement des données ajax
	 */
	onAjaxLoaded: function(strData) {
    	this.fill(strData);
  		this.move();
  		this.show();
	}
});

/**
 * Ajout statique effectif d'une fenêtre (usage interne)
 */
PsWindow.realCreate = function(strClass, strID, oContents, oOptions) {
    var oWindow = null;
    var bCache = true;
    if ($defined(oOptions.cache)) bCache = oOptions.cache;

    // Charge le singleton PsWindowManager
    var oManager = PsWindowManager.getInstance();

    // Commence par vérifier si n'existe pas déjà
    oWindow = oManager.find(strID);
    if (oWindow != null) {
        // On veut peut-être utiliser le cache
        if (bCache == true) {
	 	    if (oWindow.visible) {
		        // On fait juste un wakeup pour un éventuel effet visuel
		        oWindow.wakeup();
		    }
		    else {
		        // On réaffiche
		        oWindow.move();
		        oWindow.show();
		    }
        }
        else {
            // On utilise la fenêtre existante mais on écrase le contenu
	        // Note comme non chargée
	    	oWindow.filled = false;

	    	// Exécute à nouveau
	    	oWindow.execute(oContents);
        }
    }
    else {
        // Création de la fenêtre
        eval('oWindow = new ' + strClass + '(strID, oContents, oOptions);');

        // Enregistre dans le manager
        oManager.register(oWindow);
    }

	return oWindow;
}



/**
 * Ajout statique d'une fenêtre
 */
PsWindow.create = function(strID, oContents, oOptions) {
    return PsWindow.realCreate('PsWindow', strID, oContents, oOptions);
}

/**
 * Mise à jour statique du contenu d'une fenêtre
 */
PsWindow.update = function(strID, oContents) {
    // Charge le singleton PsWindowManager
    var oManager = PsWindowManager.getInstance();

	// Retrouve la fenêtre
    oWindow = oManager.find(strID);
    if (oWindow != null) {
        // Vide le contenu et affiche l'image d'attente
        oWindow.wait();

        // Note comme non chargée
    	oWindow.filled = false;

    	// Exécute à nouveau
    	oWindow.execute(oContents);

    	return true;
    }
    else {
        return false;
    }
}
