/**
 * Classe de support du CustomRepeater
 */
var CustomRepeater = new Class({
  /**
   * Constructeur
   */
  initialize: function (iID, oOptions) {
    oOptions = oOptions || {};
    this.id = iID;
    this.ajaxLevel2 = false;

    // checkAllPages:
    // if checkallpages option is checked, then check everything
    // unchecking does nothing special
    // if ANYTHING else gets unchecked, uncheck it.  Does not re-enable if you re-check the offending checkbox.
    // If it is checked when the form is submitted, submit ALL ids from all pages.
    this.showCheckAllPages = false;
    this.allPagesCheckbox = null;
    this.showMixState = true;
    this.allResultsLabel = '';
    this.checkboxes = document.getElementsByName(this.id + '_chk[]');
    this.countFormat = oOptions.countFormat || '';
    this.showCounts = oOptions.showCounts || false;

    if (oOptions.ajaxLevel2) {
      this.ajaxLevel2 = true;
    }
    if (oOptions.showCheckAllPages) {
      this.showCheckAllPages = true;
    }
    if (oOptions.allResultsLabel) {
      this.allResultsLabel = oOptions.allResultsLabel;
    }
    if (typeof oOptions.showMixState != 'undefined') {
      this.showMixState = oOptions.showMixState;
    }

    // Crée le tristate
    var oCR = this;
    if ($(this.id + '_gacb')) {
      var countLabel = null;

      if(this.showCounts) {
        countLabel = new Element('label', {
          text: sprintf(this.countFormat, 0),
          class: 'allResultsLabel'
        });
      }
      var selectPage = new psTriState($(this.id + '_gacb'), this.checkboxes, function () {
        oCR.checkSelected();
      }, {
          label: this.allResultsLabel,
          showMixState:this.showMixState,
          countLabel: (this.showCheckAllPages ? null : countLabel),
          countFormat: this.countFormat
          }
      );

      if (this.showCheckAllPages) {
        this.allPagesCheckbox = new psTriState(
          $(this.id + '_gacb'),
          null,
          function (checked) {
            selectPage.toggleAll(this.isChecked());
          },
          {
            label: oOptions.label,
            countLabel: countLabel,
            countFormat: this.countFormat,
            linkedCheckbox: selectPage,
            value: oOptions.allPagesValue
          }
        );
      }
    }

    if (oOptions.hasLevel2) {
      this.showHides = [];

      this.showAllElemH = $(this.id + '_shwa_header');
      this.showAllElemF = $(this.id + '_shwa_footer');
      this.hideAllElemH = $(this.id + '_hida_header');
      this.hideAllElemF = $(this.id + '_hida_footer');
      this.hidenInput = $(this.id + '_hs');

      // Crée les showhide
      // Pour cela il faut déjà les retrouver
      var aSH = $(this.id + '_tbl').getElements('.sh');
      var iNbSH = aSH.length;
      for (var iSH = 0; iSH < iNbSH; iSH++) {
        // Retrouve les éléments à masquer. On obtient leur classe sur le TR qui englobe l'élément
        var strMainClassName = aSH[iSH].className;
        var strSectionID = this.getSectionId(strMainClassName);

        this.createShowHide(strSectionID, aSH[iSH], oOptions.ajaxLevel2);
      }
      this.updateHidenSections();
    }

    this.addEvents();
  },

  /**
   * Crée les showHide rattachés
   */
  createShowHide: function (strSectionID, oElem, bAjaxLevel2) {
    var oCR = this;

    this.showHides[strSectionID] = new psShowHide(oElem, [$('sh' + strSectionID)], function () {
      oCR.updateHidenSections();
      if (bAjaxLevel2) oCR.loadLevel2(strSectionID);
    });
  },

  /**
   * Rattachement des événements
   */
  addEvents: function () {
    var oCtrl = this;

    if (this.checkboxes) {
      var iNbCheckBoxes = this.checkboxes.length;
      for (var iCheckBox = 0; iCheckBox < iNbCheckBoxes; iCheckBox++) {
        if (this.checkboxes[iCheckBox].type == 'checkbox') {
          $(this.checkboxes[iCheckBox]).addEvent('change', function () {
            oCtrl.checkSelected();
          });
        }
      }
    }

    // Au chargement, vérifie si sélections existent
    window.addEvent('domready', function () {
      oCtrl.checkSelected();
    });

    if (this.showAllElemH) {
      if (this.ajaxLevel2) {
        this.showAllElemH.addEvent('click', function () {
          ta(oCtrl.id + '_showAll');
        });
      } else {
        this.showAllElemH.addEvent('click', function () {
          oCtrl.toggleAll(false);
        });
      }
    }
    if (this.showAllElemF) {
      if (this.ajaxLevel2) {
        this.showAllElemF.addEvent('click', function () {
          ta(oCtrl.id + '_showAll');
        });
      } else {
        this.showAllElemF.addEvent('click', function () {
          oCtrl.toggleAll(false);
        });
      }
    }
    if (this.hideAllElemH) {
      this.hideAllElemH.addEvent('click', function () {
        oCtrl.toggleAll(true);
      });
    }
    if (this.hideAllElemF) {
      this.hideAllElemF.addEvent('click', function () {
        oCtrl.toggleAll(true);
      });
    }
  },

  /**
   * Vérifie si au moins une case est cochée et affiche ou non les actions globales
   */
  checkSelected: function () {
    var oDivGlobal = $(this.id + '_ga');

    if (oDivGlobal) {
      var allChecked = true;
      var bChecked = false;
      if (this.checkboxes) {
        var iNbCheckBoxes = this.checkboxes.length;
        for (var iCheckBox = 0; iCheckBox < iNbCheckBoxes; iCheckBox++) {
          if (this.checkboxes[iCheckBox].checked) {
            bChecked = true;
          } else {
            allChecked = false;
          }
        }
      }
      if (bChecked) {
        oDivGlobal.fade('in');
      }
      else {
        oDivGlobal.fade('out');
      }
      if (this.allPagesCheckbox) {
          // trigger the allPagesCheckbox
          // but don't cascade the events
          this.allPagesCheckbox.toggleAll(this.allPagesCheckbox.isChecked() && allChecked, false);

        // now toggle available actions depending on if allPages is checked.
        // if checked, then only those marked as mass action should be shown
        // otherwise show all of them.
        this.toggleActions(oDivGlobal, this.allPagesCheckbox.isChecked());
      }
    }
  },

  /**
   * récupération des checkbox sélectionnées
   */
  getSelected: function () {
    var strResult = '';
    if (this.showCheckAllPages && this.allPagesCheckbox.isChecked()) {
      strResult = this.allPagesCheckbox.getValue();
    } else if (this.checkboxes) {
      var iNbCheckBoxes = this.checkboxes.length;
      for (var iCheckBox = 0; iCheckBox < iNbCheckBoxes; iCheckBox++) {
        if (this.checkboxes[iCheckBox].checked) {
          if (strResult) strResult += ',';
          strResult += this.checkboxes[iCheckBox].value;
        }
      }
    }
    return strResult;
  },

  /**
   * Get whether or not this is a mass action (ie, if the "check all results on all pages" checkbox is checked)
   * Returns false if the checkbox isn't shown at all.
   */
  isMassAction: function () {
    return this.allPagesCheckbox && this.allPagesCheckbox.isChecked();
  },

  /**
   * Met à jour la liste des sections masquées (champ caché)
   */
  updateHidenSections: function () {
    var strHidden = '';

    var aSH = $(this.id + '_tbl').getElements('.sh');
    var iNbSH = aSH.length;
    var iNbHidden = 0;
    for (var iSH = 0; iSH < iNbSH; iSH++) {
      var strSectionID = this.getSectionId(aSH[iSH].className);

      if (this.showHides[strSectionID]) {
        if (this.showHides[strSectionID].checkHidden()) {
          if (strHidden) strHidden += ',';
          strHidden += strSectionID;
          iNbHidden++;
        }
      }
    }
    this.hidenInput.value = strHidden;

    // Met à jour la classe css des sections masquer tout / afficher tout
    if (iNbHidden == iNbSH) {
      // Tout est masqué
      if (this.showAllElemH) this.showAllElemH.className = '';
      if (this.showAllElemF) this.showAllElemF.className = '';
      if (this.hideAllElemH) this.hideAllElemH.className = 'active';
      if (this.hideAllElemF) this.hideAllElemF.className = 'active';
    }
    else if (iNbHidden == 0) {
      // Rien n'est masqué
      if (this.showAllElemH) this.showAllElemH.className = 'active';
      if (this.showAllElemF) this.showAllElemF.className = 'active';
      if (this.hideAllElemH) this.hideAllElemH.className = '';
      if (this.hideAllElemF) this.hideAllElemF.className = '';
    }
    else {
      // Etat mixte
      if (this.showAllElemH) this.showAllElemH.className = '';
      if (this.showAllElemF) this.showAllElemF.className = '';
      if (this.hideAllElemH) this.hideAllElemH.className = '';
      if (this.hideAllElemF) this.hideAllElemF.className = '';
    }

  },

  /**
   * Retourne l'identifiant de section à partir du nom de classe de l'élément parent
   */
  getSectionId: function (strMainClassName) {
    // On extrait du nom de cette classe. Attention, normalement on a en fait 2 classes (par exemple sh trm123)
    var reg = new RegExp('[0-9]+', 'g');
    return reg.exec(strMainClassName);
  },

  /**
   * Bascule l'affichage de toutes les sections
   */
  toggleAll: function (bHide) {
    var aSH = $(this.id + '_tbl').getElements('.sh');
    var iNbSH = aSH.length;
    for (var iSH = 0; iSH < iNbSH; iSH++) {
      var strSectionID = this.getSectionId(aSH[iSH].className);

      if (this.showHides[strSectionID]) {
        this.showHides[strSectionID].toggle(bHide);
      }
    }
    this.updateHidenSections();

    // Précise l'option hideAll
    $(this.id + '_ha').value = bHide;
  },

  /**
   * Toggle available actions depending on if allPages is checked.
   * if checked, then only those marked as mass action should be shown
   * otherwise show all of them.
   */
  toggleActions: function (globalContainer, showOnlyMassActions) {
    if (showOnlyMassActions) {
      globalContainer.getElements('a[data-allowMassAction!="true"]').hide();
    } else {
      globalContainer.getElements('a[data-globalAction="true"]').show();
    }
  },

  /**
   * Chargement du niveau 2
   */
  loadLevel2: function (strSectionID) {
    // Chargement du contenu de la section spécifiée
    // On soumet la vue pour déclencher une action
    var oViewInfo = PsAjax.loadViewInfo('');
    PsAjax.submitView({
      controler: _psfc,
      id: '',
      viewName: oViewInfo.viewName,
      url: oViewInfo.url,
      layout: oViewInfo.layout,
      action: this.id + '_loadLevel2',
      actionParam: strSectionID
    }, {});
  }


});


/**
 * Alias de récupération des ID sélectionnés
 */
CustomRepeater.getSelected = function (iID) {
  return WCManager.getInstance().controls.get(iID).getSelected();
};
/**
 * Get whether or not this is a mass action (ie, if the "check all results on all pages" checkbox is checked)
 */
CustomRepeater.isMassAction = function (iID) {
  return WCManager.getInstance().controls.get(iID).isMassAction();
};
