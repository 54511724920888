/**
 * Classe WebUpload
 * Support javascript du WebUpload
 */
var WebUpload = new Class({
	// Constructeur
	initialize: function(strID) {
		this.id = strID;			// identifiant unique
		this.maxFileSize = 0;		// Taille maximale
		this.viewID = '';			// ID de la vue associée
		this.ajax = false;			// Ajax?

		// Parties HTML
		if (Luceo.jsProperties && Luceo.jsProperties.upliftedUI) {
            this.html = {
                hid: null,		// Champ caché contenant le nom temporaire
                upl: null,		// imput file
                txt: null,		// Champ texte contenant le nom original
                mfs: null,		// Champ MAX_FILE_SIZE
                iframe: null,   // Iframe utilisée dans le cas ajax
                parentdiv: null,   // input file parent div
                inputaltdiv: null   // Div to be shown instead of input file
            }
        } else {
            this.html = {
                hid: null,		// Champ caché contenant le nom temporaire
                upl: null,		// imput file
                txt: null,		// Champ texte contenant le nom original
                mfs: null,		// Champ MAX_FILE_SIZE
                iframe: null   // Iframe utilisée dans le cas ajax
            }
        }
	},

	// Initialisation du contrôle
	init: function() {
		// Charge les composants HTML
		this.html.hid = $(this.id);
		this.html.upl = $(this.id + '_upl');
		this.html.txt = $(this.id + '_txt');
		this.html.mfs = $(this.id + '_mfs');

		if (Luceo.jsProperties && Luceo.jsProperties.upliftedUI) {
            this.html.parentdiv = this.html.hid.parentElement;
            this.html.inputaltdiv = this.html.hid.parentElement.getElementsByClassName('upload-sub-div')[0];
        }

		// Place les balises name
		this.html.hid.name = this.html.hid.id;
		this.html.upl.name = this.html.upl.id;
		this.html.txt.name = this.html.txt.id;
		
		// Si ajax, initialise l'iframe
		if (this.ajax) {
			this.html.iframe = $(this.id + '_frame');
			
			/**
			 * Force un rechargement
			 * Sinon chrome ne charge pas le contenu lorsque le composant
			 * est ajoutée en réponse à un appel ajax
			 */
			this.html.iframe.src = this.html.iframe.src;  
		}
	},
	
	// Ajoute les gestionnaires d'événements automatiques
	addEvents: function() {
		var oCtrl = this;
		
		if(Luceo.jsProperties && Luceo.jsProperties.upliftedUI) {
            var inputElement = this.html.upl;
            var uploadDivElement = this.html.inputaltdiv;
            this.html.inputaltdiv.onclick = function(event) {
                inputElement.click();
            }
        }
        
		// Déclenche un postback sur le onchange
		this.html.upl.onchange = function(event) {
			oCtrl.prepareForm();
			
			// Traitement ajax: upload dans une iframe
			if (oCtrl.ajax) {
			    var oForm = $('MainForm');
			    
			    var oOldTarget = oForm.target;
			    var oOldAction = oForm.action;
			    
			    oForm.target = oCtrl.html.iframe.name;
			    oForm.action = oCtrl.html.iframe.src;
			    
			    oForm.submit();
			    
			    oForm.target = oOldTarget;
			    oForm.action = oOldAction;
			    
			    oCtrl.html.iframe.addEvent('load', function() {
			        // Recharge l'IFrame car le contenu a été modifié et a priori il y a un bug mootools
			        var oIframe = new IFrame(oCtrl.id + '_frame');
			        var oDoc = PsUtils.getIframeDocument(oIframe);
			        
			        /**
			         * Note 3.2: Pour une raison inconnue, Mootools bugge à ce stade, on va donc se débrouiller sans lui
			         * (Régression suite à mise à jour sans doute, car ça a marché)
			         */
					var oFile = oDoc.getElementById(oCtrl.id + '_upl');

			        if (oFile) {
			        	var strFile = '';

			        	if (oFile.firstChild.textContent) {
			        		strFile = oFile.firstChild.textContent;
			        	}
			        	else if(oFile.firstChild.innerText) {
			        		strFile = oFile.firstChild.innerText;
						}
						else if (oFile.innerHTML) {
							strFile = oFile.innerHTML;
      					}
			            
			            
						// Décode
						var oFile = JSON.decode(strFile);

			            // Met à jour les champs correspondants du contrôle
			            oCtrl.html.txt.value = oFile.name;
			            
			            // Le champ caché contient toutes les informations en json
			            oCtrl.html.hid.value = strFile;
			        }
			        
			        // Ne provoque le postback réel que maintenant
			        ta(oCtrl.id, '', oCtrl.viewID);
			    });

			}
			else {
				ta(oCtrl.id, '', oCtrl.viewID);
			}

		}
	},
	
  
	// Prépare le formulaire à l'envoi
	prepareForm: function() {
		var oForm = $('MainForm');

		// Ajoute l'encodage multipart
		oForm.encoding = "multipart/form-data";

		// Ajoute un champ MAX_FILE_SIZE
		var strMaxFileSize = '<input type="hidden" name="MAX_FILE_SIZE" value="' + this.maxFileSize + '" />';
		this.html.mfs.innerHTML = strMaxFileSize;
	}
});
