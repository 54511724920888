/**
 * Classe WebCalendar
 * Fonctions de support du WebCalendar
 */
var WebCalendar = new Class({
	initialize: function(strID, oOptions) {

		this.id = strID;
		this.options = oOptions;

		// Stocke tout de suite la valeur initiale
		var strCtrlValue = $(strID).value;

		// Création du DatePicker
		var oDPOptions = {
			pickerClass: 'datepicker_vista',
			toggleElements: '#' + strID + '_img',
			allowEmpty: true,
			format: _dtsettings.format,
			inputOutputFormat: _dtsettings.format,
			days: [PsLocal.JS_DIMANCHE, PsLocal.JS_LUNDI, PsLocal.JS_MARDI, PsLocal.JS_MERCREDI, PsLocal.JS_JEUDI, PsLocal.JS_VENDREDI, PsLocal.JS_SAMEDI],
			months: [PsLocal.JS_JANVIER, PsLocal.JS_FEVRIER, PsLocal.JS_MARS, PsLocal.JS_AVRIL, PsLocal.JS_MAI, PsLocal.JS_JUIN, PsLocal.JS_JUILLET, PsLocal.JS_AOUT, PsLocal.JS_SEPTEMBRE, PsLocal.JS_OCTOBRE, PsLocal.JS_NOVEMBRE, PsLocal.JS_DECEMBRE],
			startDay: _dtsettings.startDay
		};
        if (Luceo && Luceo.jsProperties && Luceo.jsProperties.upliftedUI) {
            oDPOptions.yearsPerPage = 24;
        }
		// Dates min et max
		if (oOptions.minDate) oDPOptions.minDate = { date: oOptions.minDate, format: _dtsettings.format };
		if (oOptions.maxDate) oDPOptions.maxDate = { date: oOptions.maxDate, format: _dtsettings.format };

		this.cal = new DatePicker('#' + strID, oDPOptions);

		// Force la date initiale si celle ci est nulle ou la date par défaut
		if ( !strCtrlValue || strCtrlValue == PsLocal.JS_WEBCALENDAR_DEFAULT) {
			$(strID).value = PsLocal.JS_WEBCALENDAR_DEFAULT;
			$(strID).getNext().value = PsLocal.JS_WEBCALENDAR_DEFAULT;

		}

		// Event on change
		var oWebCalendar = this;


		// Reset
		if ($(strID + '_rst')) {
			$(strID + '_rst').addEvent('click', function(){
				// if this is already the default, we don't need to reset
				if ( $(strID).value === PsLocal.JS_WEBCALENDAR_DEFAULT ) {
					return false;
				}

				$(strID).value = PsLocal.JS_WEBCALENDAR_DEFAULT;
				$(strID).getNext().value = PsLocal.JS_WEBCALENDAR_DEFAULT;

				if ($(strID + '_itv')) {
					$(strID + '_itv').innerHTML = '';
				}

				// Option autopostback
				if (oWebCalendar.options.apb) {
					ta(oWebCalendar.id);
				}

				jQuery('#' + strID).trigger('select');

				if (document.getElementById(oWebCalendar.id).onchange) {
					document.getElementById(oWebCalendar.id).onchange();
				}
			});
		}

		this.cal.options.onSelect = function(dtDate) {
			oWebCalendar.showInterval(dtDate);

			// Option autopostback
			if (oWebCalendar.options.apb) {
					ta(oWebCalendar.id);
			}

			jQuery('#' + strID).trigger('select', dtDate);

			if (document.getElementById(oWebCalendar.id).onchange) {
				document.getElementById(oWebCalendar.id).onchange();
			}
		};

		// Evenement sur le onblur pour mofidication à la main
		$(this.id).getNext().addEvent('blur', function() {
			var strValue = $(oWebCalendar.id).getNext().value;
				if (strValue && strValue != PsLocal.JS_WEBCALENDAR_DEFAULT) {
				// Vérifie la date saisie. Pour cela on va déjà regarder si le parser
				// Standard de mootools sait parser
				// Ca élimine les erreurs de type caractères incorrects
				var dtDate = Date.parse(strValue.replace(/\./g, '/'));
				if (dtDate == 'Invalid Date') {
						alert(PsLocal.JS_WEBCALENDAR_ERR_INVALID);
						$(oWebCalendar.id).getNext().focus();
						return false;
				}
				else {
						// Maintenant qu'on a un truc qui ressemble à une date, on peut vérifier
						// Si c'est bien une date au format attendu
						var dtDate2 = oWebCalendar.cal.unformat(strValue, _dtsettings.format);
						var dtDate1 = oWebCalendar.cal.unformat(oWebCalendar.options.minDate, _dtsettings.format);
						if (oWebCalendar.cal.format(dtDate2, _dtsettings.format) != strValue) {
								// La double conversion donne un résultat différent => problème
							alert(PsLocal.JS_WEBCALENDAR_ERR_INVALID);
							return false;
					}
					else {
							// La date est OK, reste à vérifier si elle entre dans l'intervale
							if (dtDate2.diff(dtDate1) > 0) {
							alert(PsLocal.JS_WEBCALENDAR_ERR_MIN.replace('%s', oWebCalendar.options.minDate));
							$(oWebCalendar.id).value = PsLocal.JS_WEBCALENDAR_DEFAULT;
							$(oWebCalendar.id).getNext().value = PsLocal.JS_WEBCALENDAR_DEFAULT;

								return false;
						}

							if (dtDate2.diff(oWebCalendar.options.maxDate) < 0) {
							alert(PsLocal.JS_WEBCALENDAR_ERR_MAX.replace('%s', oWebCalendar.options.maxDate));
							$(oWebCalendar.id).value = PsLocal.JS_WEBCALENDAR_DEFAULT;
							$(oWebCalendar.id).getNext().value = PsLocal.JS_WEBCALENDAR_DEFAULT;

								return false;
						}

						// On affiche l'intervale
						oWebCalendar.showInterval(dtDate2);
					}
				}
			}

			eval(oWebCalendar.options.onchange);
		});


	},

		// display date diff, compared to current date
		showInterval: function(dtDate) {
				var oItv = $(this.id + '_itv');

				if (oItv) {
						var strTypeIntervale	= 'y';
						var strDiffInterval	 = 'year';
						var strIntervalString = PsLocal.JS_WEBCALENDAR_ANS;
						var doDiff						= true;
						var strIntervale			= '';

						if ($defined(this.options.intervale)) {
								strTypeIntervale = this.options.intervale;
						}

						if (strTypeIntervale == 'y') {
								// special case
								doDiff						= false;
								strIntervalString = PsLocal.JS_WEBCALENDAR_ANS;

								// get real diff year, instead of rounded one
								var ageDiff = Date.now() - dtDate.getTime();
								var ageDate = new Date(ageDiff); // miliseconds from dtDate

								strIntervale = Math.abs(ageDate.getUTCFullYear() - 1970);
						} else if (strTypeIntervale == 'm') {
								strDiffInterval	 = 'month';
								strIntervalString = PsLocal.JS_WEBCALENDAR_MOIS;
						} else if (strTypeIntervale == 'w') {
								strDiffInterval	 = 'week';
								strIntervalString = PsLocal.JS_WEBCALENDAR_SEMAINES;
						} else if (strTypeIntervale == 'd') {
								strDiffInterval	 = 'day';
								strIntervalString = PsLocal.JS_WEBCALENDAR_JOUR;
						}


						if (doDiff) {
								// diff date if necessary
								strIntervale = dtDate.diff(new Date(), strDiffInterval);
						}

						if (strIntervale) {
								oItv.innerHTML = strIntervale + ' ' + strIntervalString;
						} else {
								oItv.innerHTML = '';
						}
				}

		}

});

WebCalendar.create = function(strID, oOptions) {
	new WebCalendar(strID, oOptions);
}
