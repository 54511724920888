/**
 * Module WebList
 * Fonctions de support des WebLists
 * A transformer en classe
 */

var timer = null;
var chaine = "";
function startsWith (str1, str2) {
	var k = str1.substring(0, str2.length);
	return (str2.toLowerCase() == k.toLowerCase());
}

function clearChaine () {
	chaine="";
}

function liDown (list) {
	// Si pas IE on arrete car Firefox gère en standard :
	if (!document.all) return true;

	var c = event.keyCode;
	if (c < 48 && c!=32) return true;

	var s = String.fromCharCode(c);
	chaine += s;
	var n = list.selectedIndex;
	var ok = false;

	if (chaine.length > 1 && startsWith(list.options[n].text, chaine)) ok=true;

	for (var i=n+1; i < list.options.length && !ok; i++) {
		if (startsWith(list.options[i].text, chaine)) {
			n = i;
			ok = true;
		}
	}
	for (var i=0; i < n && !ok; i++) {
		if (startsWith(list.options[i].text,chaine)) {
			n = i;
			ok = true;
		}
	}
	list.selectedIndex = n;

	if (timer!=null) clearTimeout(timer);
	timer = setTimeout("clearChaine();",500);
	return false;
}
