/**
 * Module contenant les popups de l'application
 */

var PsPopups = new Class({
	initialize: function() {
	}
});


/**
 * Gestion de la popup courrier
 */
function popupCourrier(strViewID, oOptions) {
	/**
	 * Options:
	 * iIDAffectation
	 * iIDCandidat
	 * iIDPoste
	 * strWorkflowAction
	 * strConfirm
	 * strActionParam		ID passé en action param si on n'affiche pas la popup
		 * massAction
	 */

	PsUtils.startLoading();

	// Check if there's a aurico package order to display
	var auricoUrl = PsAjax.remoteMethod('WorkflowCandidat::getDisplayAuricoPopup', [oOptions.iIDAffectation, oOptions.strWorkflowAction], {sync: true});
	if (auricoUrl.length > 0) {
		popupCenter(auricoUrl, '_blank', 620, 570);
	}

	// AJAX call to display a WF popup (or not)
	var bPopup = false;

	var strPopup = PsAjax.remoteMethod('WorkflowCandidat::getAffichePopupCourrier', [oOptions.iIDAffectation, oOptions.strWorkflowAction, oOptions.iIDPoste], {sync: true});

	// Nettoie les caractères qui trainent
	strPopup = strPopup.trim();

	// Si la longueur de la chaine est inférieure à 8 caractères, c'est qu'elle ne contient pas un chemin XML
	// qui doit contenir au moins "/popups/"
	if (strPopup.length > 8) {
		bPopup = true;
	}

	if (bPopup) {
		var strViewURL = 'popup/workflow_candidat';
		// On autorise une popup spécifique. Pour cela on regarde s'il existe une fonction javascript
		// Qui correspond à cette popup
		var aPopupInfo = strPopup.split('/');
		var strCallback = aPopupInfo[2].replace(/-/g, '_');
		if (aPopupInfo[2]) {
			eval('if (PsPopups.' + strCallback + ') strViewURL = PsPopups.' + strCallback + '();');
		}

		// Affichage effectif de la popup
		// On utilise une PsWindow
		if (oOptions.massAction === undefined) {
			oOptions.massAction = '';
		}

		//check if we are coming from quickview
		var quickzoom = document.getElementsByClassName("content_quickZoom is-popup popup-quickzoom");
		var fromQZ = false;
		for (var i = 0; i < quickzoom.length; i++) {
			if (quickzoom[i].style.cssText.match("visibility: visible")) {
				fromQZ = true;
			}
		}

		PsDialog.create('wdwCand', {
			loadView: true,
			HTTPmethod: 'get',
			controler: _psfc,
			viewId: 'wdwCand',
			viewUrl: strViewURL,
			viewQueryString: 'iIDAffectation=' + oOptions.iIDAffectation + '&iIDCandidat=' + oOptions.iIDCandidat + '&iIDPoste=' + oOptions.iIDPoste + '&workflowAction=' + oOptions.strWorkflowAction + '&massAction=' + oOptions.massAction + "&fromQuickzoom=" + fromQZ,
			stripScripts: false
		}, {
			cache: false
		});
	}
	else {
		var bOK;
		if (oOptions.strConfirm) {
			bOK = confirm(oOptions.strConfirm);
		}
		else {
			bOK = true;
		}
		if (bOK) {
			if (oOptions.iIDAffectation === 0) {
				ta(oOptions.strWorkflowAction, oOptions.strActionParam, strViewID);
			} else {
				if (oOptions.massAction) {
					oOptions.strWorkflowAction = 'massaction:' + oOptions.strWorkflowAction;
					oOptions.strActionParam = oOptions.massAction;
				}

				// Are we in the context in the candidate lists?
				var isAjax = 'auto';
				if (strViewID === 'vwAf') {
					isAjax = 'false';
				}

				ta(oOptions.strWorkflowAction, oOptions.strActionParam, strViewID, isAjax);
			}
		}
	}
}

/**
 * Gestion de la popup de motif de refus poste
 */
function popupMotifRefusPoste(strViewID, oOptions) {
	/**
	 * Options:
	 * iIDPoste
	 * strWorkflowAction
	 */

	// Affichage effectif de la popup
	// On utilise une PsWindow
	PsDialog.create('wdwPoste', {
		loadView: true,
		HTTPmethod: 'get',
		controler: _psfc,
		viewId: 'wdwPoste',
		viewUrl: 'popup/etat_poste',
		viewQueryString: 'iIDPoste=' + oOptions.iIDPoste + '&workflowAction=' + oOptions.strWorkflowAction,
		stripScripts: false
	}, {
		cache: false
	});
}


/**
 * Gestion de la popup de workflow poste
 */
function popupWorkflowPoste(strViewID, oOptions) {
	/**
	 * Options:
	 * iIDPoste
	 * strWorkflowAction
	 * strConfirm
	 * strActionParam
	 */

	// Utilise un appel AJAX pour savoir s'il faut afficher la popup
	var bPopup = false;

	var strPopup = PsAjax.remoteMethod('PosteActions::getAffichePopupWorkflow', [oOptions.iIDPoste, oOptions.strWorkflowAction], {sync: true});

	// Nettoie les caractères qui trainent
	strPopup.trim();

	// Si la longueur de la chaine est inférieure à 8 caractères, c'est qu'elle ne contient pas un chemin XML
	// qui doit contenir au moins "/popups/"
	if (strPopup.length > 8) {
		bPopup = true;
	}

	if (bPopup) {
		// Affichage effectif de la popup
		// On utilise une PsWindow
		PsDialog.create('wdwPoste', {
			loadView: true,
			HTTPmethod: 'get',
			controler: _psfc,
			viewId: 'wdwPoste',
			viewUrl: 'popup/workflow_poste',
			viewQueryString: 'iIDPoste=' + oOptions.iIDPoste + '&workflowAction=' + oOptions.strWorkflowAction,
			stripScripts: false
		}, {
			cache: false
		});
	}
	else {
		var bOK;
		if (oOptions.strConfirm) {
			bOK = confirm(oOptions.strConfirm);
		}
		else {
			bOK = true;
		}
		if (bOK) {
			ta(oOptions.strWorkflowAction, oOptions.strActionParam, strViewID);
		}
	}
}

/**
 * Gestion de la popup de postit
 */
function popupPostit(iIDObjet, iTypeObjet, iIDUtilisateur) {
	// On utilise une PsWindow
	PsWindow.create('wdwPostit', {
		loadView: true,
		HTTPmethod: 'get',
		controler: _psfc,
		viewId: 'wdwPostit',
		viewUrl: 'popup/postit',
		viewQueryString: 'iIDObjet=' + iIDObjet + '&iTypeObjet=' + iTypeObjet + '&iIDUtilisateur=' + iIDUtilisateur,
		stripScripts: false
	}, {
		cache: false,
		noframe: true,
		iframeShow: true,
		mainClass: 'content_ifb is-tooltip is-postit',
		background: '#ffff99'
	});
}

/**
 * Displays a browser popup in the center of the screen
 * @param url		URL to display in the popup
 * @param title		Popup title
 * @param w			Popup width
 * @param h			Popup heights
 */
function popupCenter(url, title, w, h) {
	// Fixes dual-screen position
	var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
	var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

	var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
	var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

	var left = ((width / 2) - (w / 2)) + dualScreenLeft;
	var top = ((height / 2) - (h / 2)) + dualScreenTop;
	var newWindow = window.open(url, title, 'location=yes,status=yes,resizable=yes,scrollbars=yes,width=' + w + ',height=' + h + ',top=' + top + ',left=' + left);

	// Puts focus on the newWindow
	if (window.focus) {
		newWindow.focus();
	}
}

/**
 * Displays a document preview
 * @param candId to whom document in need to sent
 */
function adhocForm(candId) {
	PsDialog.create('wdwAdhoc', {
		loadView: true,
		HTTPmethod: 'get',
		controler: _psfc,
		viewId: 'wdwAdhoc',
		viewUrl: 'popup/adhoc_form',
		viewQueryString: 'candidateId=' + candId,
		stripScripts: false
	}, {
		cache: false
	});
}

/**
 * Displays a document preview
 * @param url to display in the popup
 */
function docPreview(url) {
	PsDialog.create('wdwPreview', {
		loadView: true,
		HTTPmethod: 'get',
		controler: _psfc,
		viewId: 'wdwPreview',
		viewUrl: 'popup/doc_preview',
        viewQueryString: 'docUrl=' + url,
		stripScripts: false
	}, {
		cache: false
	});
}

function offerletterForm(candId, reqId, historyId) {
    reqId = reqId || 0;
    historyId = historyId || 0;

	PsDialog.create('wdwOfferletter', {
		loadView: true,
		HTTPmethod: 'get',
		controler: _psfc,
		viewId: 'wdwOfferletter',
		viewUrl: 'popup/offerletter_form',
		viewQueryString: 'candidateId=' + candId + '&requisitionId=' + reqId + '&oldHistoryId=' + historyId,
		stripScripts: false
	}, {
		cache: false
	});
}

/**
 * Request texting consent approval of candidateId
 * @param int candId
 */
function requestTextingEmail(candId) {
    PsDialog.create('wdwRequestTextingConsent', {
		loadView: true,
		HTTPmethod: 'get',
		controler: _psfc,
		viewId: 'wdwRequestTextingConsent',
		viewUrl: 'popup/requestTextingConsent',
		viewQueryString: 'candidateId=' + candId,
		stripScripts: false
	}, {
		cache: false
	});
}
