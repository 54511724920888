/**
 * Module WebValueCheckList
 * Fonctions de support du WebValueCheckList
 * A transformer en classe
 */

// Initialisation complète d'un WebValueCheckList
function wvcl_init(strID, strValues) {
	// Retrouve la table principale
	var oTbl = document.getElementById(strID + '_t');
	if (!oTbl) return false;

	// Boucle sur les TBODY
	var aTBodies = oTbl.getElementsByTagName('tbody');
	var oGroupTBody = null;

	for (var iBody = 0; iBody < aTBodies.length;   iBody ++) {
		if (aTBodies[iBody].className == 'gr') {
			oGroupTBody = aTBodies[iBody];
		}
		else if(aTBodies[iBody].className == 'li') {
			wvcl_initSection(aTBodies[iBody], oGroupTBody, strValues);
		}
	}
}

// Initialise une section d'un WebValueCheckList
function wvcl_initSection(oTBody, oGroupTBody, strValues) {
	// Retrouve l'élément parent des images
	var oParent = $(oGroupTBody).getElement('.chk');
	var aCheckBoxes = $(oTBody).getElements('input');

	if (oParent) {
		identifyTriStatesForIndeedIntegration(oParent, aCheckBoxes);

	    if(strValues.indexOf('|') != '-1'){

			var aValues = strValues.split('|');
			for(var i= 0; i < aValues.length; i++)
			{
				new psTriState(oParent, aCheckBoxes, '', {checkValues: aValues[i]}); // Crée une case a cocher pour cocher toutes les cases avec la valeur aValues[i]
				// Crée un element span html, contenant un slash
				if(i != (aValues.length)-1){ // pas d'affichage sur la derniere occurence
					var slash = new Element('span', {
						'text':   	' / '
					});
					slash.inject(oParent, 'bottom');
				}
			}

		}else{
			// Crée un tristate
			new psTriState(oParent, aCheckBoxes); // ancienne methode
		}
	}
}

// For the Indeed Integration there are restrictions on when the Indeed checkbox
// can be checked. We need to identify the triStates that correspond to these source
// checkboxes. This function adds special ids to the triStates in question.
function identifyTriStatesForIndeedIntegration(triState, checkBoxes) {
	// these elements are injected by inline JS from publication/edition.php onDisplay func
	var defaultTnIdElement = document.getElementById("defaultTNId");
	var indeedIdElement = document.getElementById("indeedId");
    var defaultTnId, indeedId;

	if (defaultTnIdElement) {
		defaultTnId = defaultTnIdElement.value;
	}

	if (indeedIdElement) {
		indeedId = indeedIdElement.value;
	}


	if (defaultTnId && indeedId) {
		for (var i = 0; i < checkBoxes.length; i++) {
			if (checkBoxes[i].value == defaultTnId) {
				triState.setAttribute("id", "defaultTnTriState");
			}

			if (checkBoxes[i].value == indeedId) {
				triState.setAttribute("id", "indeedTriState");
			}
		}
	}
}

function wvclIndeedPreInit(defaultTNId, indeedSourceId) {
	var checkBoxes = document.querySelectorAll("input[type=checkbox]");
	
	for (var i = 0; i < checkBoxes.length; i++) {
		if (checkBoxes[i].value == defaultTNId) {
			defaultTNCheckBox = checkBoxes[i];
		} else if (checkBoxes[i].value == indeedSourceId) {
			indeedCheckBox = checkBoxes[i];
		}
	}
	
	if (defaultTNId) {
		defaultTNElementId = document.createElement("input");
		defaultTNElementId.setAttribute("type", "hidden");
		defaultTNElementId.setAttribute("id", "defaultTNId");
		defaultTNElementId.setAttribute("name", "defaultTNId");
		defaultTNElementId.setAttribute("value", defaultTNId);
		document.body.appendChild(defaultTNElementId);
	}

	if (indeedSourceId) {
		indeedElementId = document.createElement("input");
		indeedElementId.setAttribute("type", "hidden");
		indeedElementId.setAttribute("id", "indeedId");
		indeedElementId.setAttribute("name", "indeedId");
		indeedElementId.setAttribute("value", indeedSourceId);
		document.body.appendChild(indeedElementId);
	}
}

function wvclIndeedPostInit(defaultTNId, indeedSourceId) {
	var defaultTnTriStateImages = jQuery("#defaultTnTriState [title='Unselect all']");
	var indeedTriStateImages = jQuery("#indeedTriState [title='Select all']");
	var indeedCheckBox = jQuery(":checkbox[value=" + indeedSourceId + "]");
	var defaultTNCheckBox = jQuery(":checkbox[value=" + defaultTNId + "]");

	indeedCheckBox.on("click", function() {
		indeedCheckBox.trigger("indeedCheckBox_toggle");
	});

	defaultTNCheckBox.on("click", function() {
		defaultTNCheckBox.trigger("indeedCheckBox_toggle");
	});

	indeedCheckBox.on("indeedCheckBox_toggle", function() {
		if (indeedCheckBox.prop("checked") && !defaultTNCheckBox.prop("checked")) {
			defaultTNCheckBox.click();
		}
	});

	defaultTNCheckBox.on("indeedCheckBox_toggle", function() {
		if (indeedCheckBox.prop("checked") && !defaultTNCheckBox.prop("checked")) {
			indeedCheckBox.click();
		}
	});

	for (var i = 0; i < defaultTnTriStateImages.length; i++) {
		defaultTnTriStateImages[i].addEventListener("click", function() {
			defaultTNCheckBox.trigger("indeedCheckBox_toggle");
		});
	}

	for (var i = 0; i < indeedTriStateImages.length; i++) {
		indeedTriStateImages[i].addEventListener("click", function() {
			indeedCheckBox.trigger("indeedCheckBox_toggle");
		});
	}
}