/**
 * Classe psShowHide
 * Gère un bouton +/- de masquage / affichage
 */
var psShowHide = new Class({
	/**
	 * Constructeur
	 */
	initialize: function(oParent, aElements, onchange, displayMode) {
		this.elements = aElements;
		this.onchange = onchange;
		this.displayMode = displayMode || 'block';

		this.html = {
			show: null,
			hide: null
		};

		/**
		 * Construit l'élément HTML
         */
        if (Luceo && Luceo.jsProperties && Luceo.jsProperties.upliftedUI) {
            this.html.show = new Element('i', {
                'class': 	'fa fa-angle-down drop-show',
                'title':   PsLocal.TXT_MONTRER,
                'alt':   	PsLocal.TXT_MONTRER,
                'styles':   {
                    'display': 'none'
                }
            });
        } else {
            this.html.show = new Element('img', {
                'src': 	Luceo.jsProperties.cdn + '/img/pictos/btn_plus.png',
                'title':   PsLocal.TXT_MONTRER,
                'alt':   	PsLocal.TXT_MONTRER,
                'styles':   {
                    'display': 'none'
                }
            });
        }
		this.html.show.inject(oParent, 'bottom');

        if (Luceo && Luceo.jsProperties && Luceo.jsProperties.upliftedUI) {
            this.html.hide = new Element('i', {
                'class': 	'fa fa-angle-up  drop-hide',
                'title':   PsLocal.TXT_MASQUER,
                'alt':   	PsLocal.TXT_MASQUER,
                'styles':   {
                    'display': 'none'
                }
            });
        } else {
            this.html.hide = new Element('img', {
                'src': 	Luceo.jsProperties.cdn + '/img/pictos/btn_moins.png',
                'title':   PsLocal.TXT_MASQUER,
                'alt':   	PsLocal.TXT_MASQUER,
                'styles':   {
                    'display': 'none'
                }
            });
        }
		this.html.hide.inject(oParent, 'bottom');

		this.addEvents();
	},

	/**
	 * Attache les événements
	 */
	addEvents: function() {
		var oCtrl = this;

		this.html.show.addEvent('click', function(){oCtrl.toggle(false);});
		this.html.hide.addEvent('click', function(){oCtrl.toggle(true);});

		// Au chargement, met à jour le statut
		window.addEvent('domready', function(){
			oCtrl.updateStatus(true);
		});
	},

	/**
	 * Vérifie le statut et affiche le bon bouton
	 */
	updateStatus: function(bLoading) {
		if (this.checkHidden()) {
			this.html.show.setStyle('display', 'block');
			this.html.hide.setStyle('display', 'none');
		}
		else {
			this.html.hide.setStyle('display', 'block');
			this.html.show.setStyle('display', 'none');
		}

		if (!bLoading && this.onchange) this.onchange();
	},

	/**
	 * Vérifie si masqué ou visible
	 */
	checkHidden: function() {
		// Boucle sur les éléments pour vérifier leur visibilité
		// Si un seul est masqué, tout est considéré comme masqué
		var bHidden = false;
		var iNbElements = this.elements.length;
		for (var iElem = 0; iElem < iNbElements;   iElem ++) {
		    if ($(this.elements[iElem]).getStyle('display') == 'none') {
		    	bHidden = true;
		        break;
		    }
		}
		return bHidden;
	},


	/**
	 * Bascule
	 */
	toggle: function(bHide) {

		var iNbElements = this.elements.length;
		for (var iElem = 0; iElem < iNbElements;   iElem ++) {
		    var oElem = $(this.elements[iElem]);
			if (bHide) {
				oElem.setStyle('display', 'none');
			}
			else {
				oElem.setStyle('display', this.displayMode);
			}
		}
		this.updateStatus();
	}

});
