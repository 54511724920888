/**
 * Classe PsTip
 * Fenêtre javascript d'infobulle
 * Hérite de PsWindow
 */
var PsTip = new Class({
	Extends: PsWindow,

	/**
	* Constructeur
	* Attention, on n'a pas de vrai polymorphisme
	* On ne peut donc pas appeler le constructeur du parent et
	* s'attendre à ce qu'il appelle par exemple le bluid surchargé
	*
	* Options (en plus de celles de psWindow):
	* lockable  Si true, convertible en fenêtre
	*/
	initialize: function(strID, oContents, oOptions) {
		this.id = strID;			// identifiant unique

		// Enregistrement
		PsWindowManager.getInstance().register(this);

        var specialTipPaths = ['tip/candidat/transition', 'tip/candidat/actions_wflw'];
        if (oContents
            && oContents.viewUrl
            && specialTipPaths.indexOf(oContents.viewUrl) > -1
        ) {
            oOptions['attachpos'] = ['upperRight', 'bottomLeft'];
        }

		// Initialisation effective de l'objet
	    this.initObject(strID, oContents, oOptions);

		// A la création de l'objet, on crée aussi la DIV elle même
		this.build();

		// Création effective
		if ($('MainForm')) {
			this.html.main.inject($('MainForm'), 'bottom');
		}
		else {
			this.html.main.inject(document.body, 'bottom');
		}

		// Ajoute les événements liés
		this.addEvents();

		// Execute
		this.execute(oContents);
	},

	initObject: function(strID, oContents, oOptions) {
		// Ajoute ses options propres
		this.parentIndex = null;	// Indice de l'infobulle parent
		this.lockable = false;		// "punaise" ou pas
		this.locked = false;		// Infobulle locké ou pas

	 	// Ecrasement des options
	 	if ($defined(oOptions.lockable)) this.lockable = oOptions.lockable;

	    // Force un certain nombre d'options
	    oOptions.autoHide = 200;
	    oOptions.noframe = false;
	    oOptions.showWait = true;
	    oOptions.modal = false;

		// Regarde si fenêtre père et lie si c'est le cas
		if (oOptions.attach.psTipIndex) {
			oParent = PsWindowManager.getInstance().windows[oOptions.attach.psTipIndex];
			if (oParent) {
				this.parentIndex = oParent.index;
				// Annule un éventuel hide sur le père
				oParent.keepAlive();
			}
		}

		// Constructeur du parent
		this.parent(strID, oContents, oOptions);

	    // Contenu propre
		this.html.lockbar = null;   // Barre avec punaise
		this.html.lock = null;         // Punaise

	},
	
	
	/**
	* Création du code HTML. On ajoute la possibilité de
	* basculer en affichage fenêtre
	*/
	build: function() {
	    // Création par défaut
	    this.parent();

	    // Div de droite
		if (this.lockable) {
			// Barre avec punaise
			this.html.lockbar = new Element('div', {
			'class':    'tble',
			'styles':   {
			    'display': 'block'
			}
			});
			this.html.lockbar.inject(this.html.topbar, 'top');

			// Punaise
			this.html.lock = new Element('img', {
			 'src': 	Luceo.jsProperties.cdn + '/img/puces/ico_punaise.gif',
			 'alt': 	'Pin'
			});
			this.html.lock.inject(this.html.lockbar, 'bottom');
		}

		// Handle et boutons close existent déjà
		// Par contre il faut les masquer par défaut
		this.html.handle.setStyle('display', 'none');

	},

	// Execution
	execute: function(oContents) {
	    // Dans cette classe, à cause de la gestion parent / enfant
	    // On doit quoi qu'il arrive afficher tout de suite
	    // Sinon le parent est détruit avant l'affichage de l'enfant
	    this.show();
	    
	    this.parent(oContents);
	},

	
	// Remplit à partir d'un contenu
	fill: function(strContent) {
	    this.parent(strContent);

		// Pour chaque élément du contenu de type image on ajoute l'index courant
		// Ca permettra de gérer la notion de père/fils
		var aElems = this.html.contents.getElements('a');

		// Attention, dans le each this ne fait plus référence à l'objet
		var oThisWindow = this;
		$each(aElems, function(oElem, iIndex) {
			oElem.psTipIndex = oThisWindow.index;
		});

	},
	
	// Sur réveil: Retourne à sa position de départ
	wakeup: function() {
		new Fx.Move(this.html.main, {
			relativeTo: $(this.html.parent),
		  	position: this.attachpos[1],
		  	edge: this.attachpos[0],
			duration: 400
		}).start();
	},

	// Rend une infobulle visible
	show: function() {
		// On masque toutes les fenetres de type tip non lockées
		// Attention, dans le each this ne fait plus référence à l'objet
		var oThisWindow = this;
		$each(PsWindowManager.getInstance().windows, function(oWindow, iIndex) {
			if(oWindow) {
				if (oWindow.id != oThisWindow.id
				    && oWindow.index != oThisWindow.parentIndex
					&& ($defined(oWindow.locked) && oWindow.locked == false)) oWindow.hide();
         }
		});

		// On affiche
		this.parent();
	},

	// Rend une infobulle invisible
	hide: function() {
		// Si il y a un enfant, on maintient ouvert tant que l'enfant est visible
		// Pour cela on boucle sur les fenêtres de la page pour voir celles qui ont
		// Cette fenêtre en parent
		var oManager = PsWindowManager.getInstance();
		for (iIndex = 1; iIndex < oManager.windows.length; iIndex ++) {
		    if (oManager.windows[iIndex]) {
			    if (oManager.windows[iIndex].parentIndex == this.index) {
			        if (oManager.windows[iIndex].visible) return false;
			    }
         }
		}
		
		// Si lockée alors on délocke
		if (this.locked) this.unlock();
		
		this.parent();
	},


	// Convertit en fenêtre fixe
	lock: function() {
		this.html.lockbar.style.display = 'none';
		this.html.handle.style.display = 'block';

		// Note la popup comme étant lockée
		this.locked = true;
	},

	// Convertit en fenêtre mobile
	unlock: function() {
		this.html.lockbar.style.display = 'block';
		this.html.handle.style.display = 'none';

		// Note la popup comme étant délockée
		this.locked = false;
	},

	// Commence un décompte de suppression
	countDown: function() {
		if (this.timer) clearTimeout(this.timer);

		if (!this.locked && this.autoHide > 0) {
			this.timer = setTimeout('var oWindow = PsWindowManager.getInstance().windows[' + this.index + ']; if (oWindow) oWindow.hide()', this.autoHide);

			// S'il a un parent, met un countdown aussi sur le parent
			if (this.parentIndex) {
				oParent = PsWindowManager.getInstance().windows[this.parentIndex];
				if (oParent) {
					oParent.countDown();
				}
			}
		}
	},

	// Ajoute les événements
	addEvents: function() {
		this.parent();
		
		var oWindow = this;
	
		if (this.lockable) {
			$(this.html.lockbar).addEvent('dblclick', function(event){ oWindow.lock(); });
			$(this.html.lock).addEvent('click', function(event){ oWindow.lock(); });
		}
		
		$(this.html.main).addEvent('contextmenu', function(event) {
		    // Augmente le délai de masquage
			oWindow.autoHide = 5000;
		});
	}

});

/**
 * Ajout statique d'une fenêtre
 */
PsTip.create = function(strID, oContents, oOptions) {
	// Options par défaut
	if (! oOptions.delay) oOptions.delay = 200;

	var oAttach = $(oOptions.attach);
	
	if (oOptions.delay && ! oAttach.delayTimer) {
	    oAttach.delayTimer = setTimeout(function(){ PsWindow.realCreate('PsTip', strID, oContents, oOptions); }, oOptions.delay);
	    
	    // Ajoute l'événement d'annulation du timer
		oAttach.addEvent('mouseout', function(event){
			if (oAttach.delayTimer) {
				clearTimeout(oAttach.delayTimer);
				oAttach.delayTimer = null;
			}
		});

	}
	else {
		return PsWindow.realCreate('PsTip', strID, oContents, oOptions);
	}
}
