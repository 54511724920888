/**
 * Module WebTab
 * Fonctions de support du WebTab
 * A transformer en classe
 */
function wtab_change(strID, iNew) {
	var oTxt = $(strID);
	
	
	var iOld = oTxt.value;
	if (iOld == '') iOld = 0;

	$(strID + '_' + iOld).className = '';
	$(strID + '_' + iNew).className = 'actif';

	$(strID + iOld + '_body').style.display = 'none';
	$(strID + iNew + '_body').style.display = 'block';

	oTxt.value = iNew;
}