/**
 * Gestion javascript de la fenêtre quickzoom
 */
var PsQuickzoom = new Class({
	Extends: PsWindow,

	/**
	* Constructeur
	* Attention, on n'a pas de vrai polymorphisme
	* On ne peut donc pas appeler le constructeur du parent et
	* s'attendre à ce qu'il appelle par exemple le bluid surchargé
	*
	* Options (en plus de celles de psWindow):
	* lockable  Si true, convertible en fenêtre
	*/
	initialize: function(strID, oContents, oOptions) {
		this.id = strID;			// identifiant unique

		// Enregistrement
		PsWindowManager.getInstance().register(this);

		// Initialisation effective de l'objet
	    this.initObject(strID, oContents, oOptions);

		// A la création de l'objet, on crée aussi la DIV elle même
		this.build();

		// Création effective
		if ($('MainForm')) {
			this.html.main.inject($('MainForm'), 'bottom');
		}
		else {
			this.html.main.inject(document.body, 'bottom');
		}

		// Ajoute les événements liés
		this.addEvents();

		// Execute
		this.execute(oContents);
	},

	// Initialisation effective de l'objet
	initObject: function(strID, oContents, oOptions) {
	    // Force un certain nombre d'options
	    oOptions.modal = true;

		// Constructeur du parent
		this.parent(strID, oContents, oOptions);
		
		// On va gérer notre propre barre d'action. On annule donc le fonctionnement standard
		this.noframe = true;
		
		// ID du container
		this.containerID = oContents.containerID;
	},

	// Construit le rendu HTML
	build: function() {
	    // Création par défaut
	    this.parent();

	    // On remplace / ajoute maintenant le reste
	    this.html.main.className = 'content_quickZoom is-popup popup-quickzoom';
	    this.html.window.className = 'quickZoom';
	    this.html.minwidth.className = 'minwidth_popup';
	    this.html.top.className = 'top_qz';
		this.html.middle.className = 'middle_qz';
		this.html.bottom.className = 'btm_qz';
		
		// Handle
		this.html.handle = new Element('div', {
			'class':    'c_i_e'
		});
		this.html.handle.inject(this.html.topbar, 'top');

		// Titre
		this.html.titre = new Element('div', {
			'class':    'titre'
		});
		this.html.titre.inject(this.html.handle, 'top');


		// Icones
		this.html.icones = new Element('div', {
			'class':    'icones'
		});
		this.html.icones.inject(this.html.handle, 'top');

		// Close
		this.html.closeAnchor = new Element('a', {
		    'href': '#'
		});
		this.html.closeAnchor.inject(this.html.icones, 'bottom');

		// Image close
		this.html.closeImg = new Element('i', {
		 'class': 	'fa fa-times',
		 'alt': 	'close'
		});
		this.html.closeImg.inject(this.html.closeAnchor, 'bottom');
	},
	
	// Ajoute les événements
	addEvents: function() {
	    // Appel du parent
	    this.parent();
	    
	    // Ajoute la mise à jour du container sur le onclick
		var oWindow = this;

		// Bouton de fermeture
		if (oWindow.containerID) {
			$(oWindow.html.closeAnchor).addEvent('click', function(event) {
			    $(oWindow.containerID).value = '';
			});
		}
	}
	
});

/**
 * Ajout statique d'une fenêtre
 */
PsQuickzoom.create = function(strID, oContents, oOptions) {
	return PsWindow.realCreate('PsQuickzoom', strID, oContents, oOptions);
}