/**
 * Classe PsAjax
 * Gestion Ajax propre à Profilsoft
 */
var PsAjax = new Class({
});

/**
 * Appel asynchrone d'une méthode sur le serveur
 * Se base sur un chargement de vue destinée à cet effet (psframework/remotemethod)
 * Options: voir PsAjax.loadView
 */
PsAjax.remoteMethod = function (strMethod, aArgs, oOptions) {
    // "psCompactSerialise" les arguments
    var strEncoded = JSON.encode(aArgs);
    strEncoded = Base64.encode(strEncoded);

    // Envoie la requete
    return PsAjax.loadView({
        HTTPmethod: 'get',
        controler: _psfc,
        id: '',
        url: 'psframework/remotemethod',
        queryString: 'method=' + strMethod + '&args=' + strEncoded
    }, oOptions);
}


/**
* Loading a view
 * OViewInfo:
 * - HTTPmethod: HTTP Method
 * - controler: Name of controller
 * - id: Unique ID of the view
 * - url: external URL of the view
 * - queryString: associated queryString
 * - layout: Layout used for display
 * Options:
 * - sync: synchronous call
 * - targetElement: div in which the content is injected
 * - targetObject: The object in which the content is sent
 * - stripScripts: Removes the returned script tags (default = true)
 * - waitDiv: Shows Ajax Spinner (défaut = false)
 */
PsAjax.loadView = function (oViewInfo, oOptions) {
    // Valeur de retour (utilisé si appel synchrone)
    var strReturn = '';

    // QueryString
    var strQueryString = 'ajax=true';
    if (oViewInfo.id) strQueryString += '&viewid=' + oViewInfo.id;
    if (oViewInfo.layout) strQueryString += '&layout=' + oViewInfo.layout;
    if (oViewInfo.queryString) strQueryString += '&' + oViewInfo.queryString;

    if (oOptions.waitDiv) {
        PsUtils.startLoading(PsLocal.TXT_WAIT_LOADER_LABEL_DEFAULT);
    }

    // Complète les options
    oOptions.HTTPmethod = oViewInfo.HTTPmethod;
    oOptions.url = '/' + oViewInfo.controler + '.php' + '/' + oViewInfo.url;
    oOptions.data = strQueryString;
    oOptions.stripScripts = false;

    return PsAjax.lowLevelRequest(oOptions);
}

/**
 * Charge le viewState de la vue courante
 */
PsAjax.getViewState = function (oViewInfo) {
    var oRoot;
    if (oViewInfo.id) {
        oRoot = $(oViewInfo.id);
    }
    else {
        oRoot = document;
    }

    // Demande à tinyMCE de déclencher la synchro avec le champ textarea lié
    if (window.tinyMCE) {
        window.tinyMCE.triggerSave();
    }

    var aInputs = oRoot.getElements('input, select, textarea');
    var oViewState = new Hash();
    $each(aInputs, function (oInput, index) {
        if (oInput.name) {
            var strCleanName = oInput.name.replace(new RegExp('[]'.escapeRegExp(), 'g'), '');
            if (!oViewState.get(strCleanName)) {
                oViewState.set(strCleanName, PsUtils.getFieldValue(oInput.name));
            }
        }
    });

    // Reconstruit le champ __Action et l'ajoute au viewState
    var aAction = {};
    aAction.a = oViewInfo.action;
    aAction.ap = oViewInfo.actionParam;
    if (!aAction.ap) aAction.ap = '';

    oViewState.set(oViewInfo.id + '__Action', Base64.encode(JSON.encode(aAction)));

    // Encode le viewState
    var strEncodedViewState = JSON.encode(oViewState);

    return strEncodedViewState;
}


/**
 * Soumission d'une vue
 * oViewInfo:
 *  - controler: Nom du contrôleur
 *  - id:   ID unique de la vue
 *  - viewName:  Nom de la vue actuelle (url interne)
 *  - url:  URL externe de la vue appelée
 *  - layout:   Layout utilisé pour l'affichage
 *  - action:   Action ayant provoqué le postback
 *  - actionParam:   Paramètre de l'action précédente
 * Options: voir PsAjax.loadView
 */
PsAjax.submitView = function (oViewInfo, oOptions) {
    // C'est une soumission, on force la méthode
    oViewInfo.HTTPmethod = 'POST';

    // On récupère le viewstate
    var strEncodedViewState = PsAjax.getViewState(oViewInfo);

    // Contruit la querystring
    oViewInfo.queryString = 'viewstate=' + encodeURIComponent(strEncodedViewState);

    // Force l'élément mis à jour
    oOptions.targetObject = null;
    if (oViewInfo.id) oOptions.targetElement = oViewInfo.id;

    // Recharge la vue
    PsAjax.loadView(oViewInfo, oOptions);
}



/**
 * Exécute une requête de bas niveau
 * Réserver à un usage privé
 * Options:
 *      - HTTPmethod: méthode HTTP
 *      - url: URL
 *      - data: Querystring
 *      - sync: Appel synchrone
 *      - targetElement: div dans laquelle est injectée le contenu
 *      - targetObject: Objet dans lequel est envoyé le contenu
 *      - disableEvents: Si true, désactive les événements des webControls le temps du traitement
 *      - waitDiv: Div D'attente
 */
PsAjax.lowLevelRequest = function (oOptions) {
    /**
     * Detects IE11 context
     * @see https://stackoverflow.com/a/55438392/2590736
     */
    const isIE11 = window.msCrypto ? true : false;

    const noMootoolsForXhrIE11 = Luceo.jsProperties.noMootoolsForXhrIE11 || false;
    const noMootoolsForXhrAll = Luceo.jsProperties.noMootoolsForXhrAll || false;

    if (noMootoolsForXhrAll || (isIE11 && noMootoolsForXhrIE11)) {
        // This is the function executed on a response retrieved from xhr or cache
        const wakeUp = function (resp) {
            // this is a javascript response, execute it
            if (resp && resp.substring(0, 11) == 'javascript:') {
                eval(resp.substring(11));
            } else {
                if (oOptions.targetObject) {
                    oOptions.targetObject.onAjaxLoaded(resp);
                } else if (oOptions.targetElement) {
                    PsUtils.injectHtml(oOptions.targetElement, resp);
                }
            }

            // re-enable events before returning
            if (oOptions.disableEvents) {
                WCManager.getInstance().eventsDisabled = false;
            }
            return resp
        }

        // close the loader (spinner) if it is opened
        const closeLoader = function () {
            if (oOptions.waitDiv) {
                PsUtils.stopLoading();
            }
        }

        if (oOptions.disableEvents) {
            WCManager.getInstance().eventsDisabled = true;
        }

        // If cache is active
        if (oOptions.ajaxCache) {
            const cachedResp = psAjaxCache.getInstance().get(oOptions);
            if (cachedResp) {
                return wakeUp(cachedResp);
            }
        }

        // No cache, let's execute the HTTP request
        const xhr = new XMLHttpRequest();
        const method = oOptions.HTTPmethod || 'get';
        const isPost = ['put', 'post'].indexOf(oOptions.HTTPmethod.toLowerCase()) > -1

        // The URL might already contain some parameter so we have to merge them with oOptions.data
        urlSeparator = (oOptions.url.indexOf('?') !== -1) ? '&' : '?'

        const url = isPost ? oOptions.url : oOptions.url + (oOptions.data ? urlSeparator + oOptions.data : '');
        const body = isPost ? oOptions.data : null;
        const async = !(oOptions.sync == true)

        const addHeaders = function (xhr) {
            if (isPost) {
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            }
        }

        // uncomment to debug:
        // console.log({
        //     async: async,
        //     method: method,
        //     url: url,
        //     body: body
        // });

        if (async) {
            xhr.open(method, url);
            xhr.timeout = 30000;
            addHeaders(xhr);

            // There is currently no error handling. This should be improved
            // But this is out of scope for current PR which is just a fix for IE11
            xhr.onerror = closeLoader;
            xhr.ontimeout = closeLoader;

            xhr.onload = function () {
                if (xhr.readyState !== 4) {
                    return
                }
                closeLoader()

                if (xhr.status >= 400) {
                    return;
                }

                // save to cache
                if (oOptions.ajaxCache) {
                    psAjaxCache.getInstance().set(oOptions, xhr.responseText);
                }

                wakeUp(xhr.responseText);
            };

            xhr.send(body);
            return;

        } else {
            // synchronous request
            // there is one sync request in the app: to check if the workflow popup should be displayed
            xhr.open(method, url, false);
            addHeaders(xhr);
            xhr.send(body);

            closeLoader()

            if (xhr.status <= 400) {
                return wakeUp(xhr.responseText)
            }
        }

        return
    }

    // Below is the old code based on Mootools
    // Remove after deletion of flag atao-4514-no-mootools-for-xhr-all
    if (oOptions.disableEvents) {
        WCManager.getInstance().eventsDisabled = true;
    }

    // Valeur de retour (utilisé si appel synchrone)
    var strReturn = '';

    // Synchrone?
    var bAsync = true;
    if (oOptions.sync == true) {
        bAsync = false;
    }

    /**
     * On regarde si cache activé. Si c'est le cas on regarde si on a l'info
     * correspondante en cache. Pour cela on génère une clé unique à partir des options
     */
    if (oOptions.ajaxCache) {
        var oCacheResults = psAjaxCache.getInstance().get(oOptions);
        if (oCacheResults) {
            return PsAjax.parseResponse(oOptions, oCacheResults);
        }
    }


    if ($('psAjaxDebug')) {
        $('psAjaxDebug').innerHTML = 'method: ' + oOptions.HTTPmethod + '<br />';
        $('psAjaxDebug').innerHTML += 'url: ' + oOptions.url + '<br />';
        $('psAjaxDebug').innerHTML += 'data: ' + oOptions.data + '<br />';
        $('psAjaxDebug').innerHTML += '<hr />';
    }

    // Prépare la requête
    var oRequest = new Request.HTML({
        method: oOptions.HTTPmethod,
        url: oOptions.url,
        data: oOptions.data,
        async: bAsync,
        evalScripts: false,
        onSuccess: function (responseTree, responseElements, responseHTML, responseJavaScript) {
            if (oOptions.waitDiv) {
                PsUtils.stopLoading();
            }

            if ($('psAjaxDebug')) {
                var strDebug = responseHTML.replace(/id="/g, 'id="psAjaxDebug_');
                strDebug = responseHTML.replace(/name="/g, 'name="psAjaxDebug_');

                $('psAjaxDebug').innerHTML += strDebug;
            }

            var oResults = {
                responseHTML: responseHTML,
                responseJavaScript: responseJavaScript
            };

            /**
             * Si mise en cache demandée, on le fait à ce niveau
             */
            if (oOptions.ajaxCache) {
                psAjaxCache.getInstance().set(oOptions, oResults);
            }

            strReturn = PsAjax.parseResponse(oOptions, oResults);
        }
    });

    // Envoie
    oRequest.send();

    return strReturn;
}

/**
 * Traite les résultats d'une requête ajax de bas niveau
 */
PsAjax.parseResponse = function (oOptions, oResults) {
    var bStripScripts = true;
    if ($defined(oOptions.stripScripts)) bStripScripts = oOptions.stripScripts;

    var strReturn = oResults.responseHTML;

    // On veut conserver les javascripts dans le flux renvoyé
    if (bStripScripts == false && oResults.responseJavaScript) {
        strReturn += '<script type="text/javascript">' + oResults.responseJavaScript + '</script>';
    }

    // Si le flux renvoyé est précédé de javascript: il faut évaluer
    if (oResults.responseHTML && oResults.responseHTML.substring(0, 11) == 'javascript:') {
        var strCmd = oResults.responseHTML.substring(11);
        eval(strCmd);
    }
    else {
        // Comportement différent pour objet ou div
        if ($defined(oOptions.targetObject)) {
            oOptions.targetObject.onAjaxLoaded(strReturn);
        }
        else if (oOptions.targetElement) {
            PsUtils.setHTMLAndEval($(oOptions.targetElement), strReturn);
        }
    }

    // Traitement terminé, on peut réactiver les événements
    if (oOptions.disableEvents) {
        WCManager.getInstance().eventsDisabled = false;
    }

    return strReturn;
}

/**
 * Charge les informations sur la vue
 */
PsAjax.loadViewInfo = function (strViewID) {
    if (!strViewID) strViewID = 'main';

    if (!_psViewInfo || !_psViewInfo[strViewID]) {
        // On ne dispose pas des infos requises
        return null;
    }
    else {
        // Charge les informations sur la vue
        var strEncoded = _psViewInfo[strViewID];
        strEncoded = Base64.decode(strEncoded);
        return JSON.decode(strEncoded);
    }
}



/**
 * Envoi d'une action sur une vue
 * Réalise un POST ou une action ajax selon contexte
 * Le paramètre ajax peut prendre les valeurs suivantes:
 * 'true'
 * 'false'
 * 'auto' (défaut)
 */
PsAjax.throwAction = function (strAction, strActionParam, strViewID, strAjax, bDoNotSubmit, bNoWaitLoader, waitLoaderLabel) {
    if (!strViewID) strViewID = '';
    if (!strAjax) strAjax = 'auto';
    var bAjax = false;
    var oViewInfo = null;

    // ouverture popup d'attente
    if (!bNoWaitLoader) {
        PsUtils.startLoading(waitLoaderLabel);
    }

    if (strAjax == 'true') bAjax = true;

    /**
     * On doit déterminer si ajax ou pas, il faut donc charger les informations de la vue
     */
    if (strAjax == 'auto') {

        if (!strViewID) {
            // Ajax non activé sur les vues sans id
            bAjax = false;
        }
        else {
            // Charge la vue
            oViewInfo = PsAjax.loadViewInfo(strViewID);
            if (!oViewInfo) {
                // On ne dispose pas des infos requises => pas ajax
                bAjax = false;
            }
            else {
                if (oViewInfo.ajax) {
                    bAjax = true;
                }
                else {
                    bAjax = false;
                }
            }

        }
    }

    if (bAjax == true) {
        // Si ce n'est pas déjà fait, on charge le viewInfo
        if (!oViewInfo) oViewInfo = PsAjax.loadViewInfo(strViewID);

        // Dans le cas d'un appel ajax, l'action est locale, elle ne doit donc pas être préfixée avec le nom de la vue
        if (strAction.substring(0, strViewID.length) == strViewID) {
            strAction = strAction.substring(strViewID.length);
        }

        PsAjax.submitView({
            controler: _psfc,
            id: strViewID,
            viewName: oViewInfo.viewName,
            url: oViewInfo.url,
            layout: oViewInfo.layout,
            action: strAction,
            actionParam: strActionParam
        }, {
            waitDiv: true
        });
    }
    else {
        // Dans le cas d'un postback, l'action est envoyée au niveau le plus haut, elle doit donc être préfixée avec le nom de la vue
        var oForm = $('MainForm');
        var oActionInput = $('__Action');

        var aAction = {};
        aAction.a = strAction;
        aAction.ap = strActionParam;
        if (!aAction.ap) aAction.ap = '';

        oActionInput.value = Base64.encode(JSON.encode(aAction));

        MainForm_onSubmit();

        // Le paramètre bDoNotSubmit bloque la soumission
        // Evite les doubles soumissions sur les boutons de type submit
        if (!bDoNotSubmit) oForm.submit();
    }

}
