/**
 * Fonctions javascript liées aux dossiers
 * @version 3.0
 */
var PsDossier = new Class({
	/**
	 * Constructeur
	 */
	initialize: function(strIDDossier) {
	    this.id = strIDDossier;
	    this.activeTab = 0; // Onglet actif
		this.tabs = []; 	// Tableau des onglets

	    this.html = {
	        root: $(this.id)
		};

		if (! this.html.root) return;

		// Zone d'onglets
		this.html.tabs = this.html.root.getElement('.ss_menu_d');

		// Sections
		this.html.sectionArray = this.html.root.getElements('legend.tab');

		// Champ caché
		this.html.input = this.html.root.getElement('#txtSO');

		// Met à jour l'onglet actif à partir du champ caché
		if (this.html.input) {
		    if (this.html.input.value == '') this.html.input.value = 0;
			this.activeTab = this.html.input.value;
		}

		// Initialisation des onglets
		this.initTabs();

		// Ajoute les événements
		this.addEvents();
	},

	/**
	 * Ajoute les événements
	 */
	addEvents: function() {
		var oDossier = this;

		var iTabs = this.tabs.length;
		for (var iTab = 0; iTab < iTabs; iTab ++) {
		    // Attention, on ne peut pas passer iTab en paramètre à selectTab dans un addEvent ici
		    // Toujours le vieux pbm de références de javascript, du coup on doit passer par une fonction intermédiaire.
			this.addEventClick(iTab);
		}
	},

	/**
	 * Ajoute l'évévenement onclick
	 */
	addEventClick: function(iTab) {
		var oDossier = this;
		this.tabs[iTab].addEvent('click', function(event){ oDossier.selectTab(iTab); return false;});
	},

	/**
	 * Transforme toutes les sections de formulaire en onglets
	 */
	initTabs: function() {
		if (!this.html.tabs) return;

		// Retrouve toutes les sections de la page
		var iNbSections = this.html.sectionArray.length;

		// S'il n'y a qu'une section, on se contente de la réafficher
		if (iNbSections < 2) {
			if (this.html.sectionArray[0]) this.html.sectionArray[0].getParent().getParent().style.display = 'block';
			this.html.tabs.style.display = 'none';
		}
		else {
			for (var iSec = 0; iSec < iNbSections; iSec ++) {
				var oParent = this.html.sectionArray[iSec].getParent().getParent();
				if (! oParent) return;

				if (iSec) {
				    // Ajoute un séparateur
			   		var oSep = new Element('span', {
			   		'class': 	'sep',
					'text': 	' | '
					});
					oSep.inject(this.html.tabs, 'bottom');
				}

			    // Créer la section et l'ajoute au container
		   		this.tabs[iSec] = new Element('a', {
					'href': 	'#',
					'text': 	this.html.sectionArray[iSec].innerHTML
				});
				this.tabs[iSec].inject(this.html.tabs, 'bottom');

			    // Regarde s'il y a des champs en erreur dans la section
			    var aErrors = oParent.getElements('span.r');
			    if (aErrors.length) {
			    	this.tabs[iSec].style.color = 'red';

					new Element('span', {
			   		'class': 	'r',
			   		'text':     '*'
					}).inject(this.html.tabs, 'bottom');
				}


				// Modifie le titre
				this.html.sectionArray[iSec].style.display = 'none';

				var oDiv = new Element('div', {
		   		'class': 	'entete_page_communes'
				});
				oDiv.inject(oParent, 'top');

				new Element('h2', {
		   		'class': 	'titre_commun',
		   		'text':     this.html.sectionArray[iSec].innerHTML
				}).inject(oDiv, 'bottom');
			}

			// Affiche le 1er onglet
			this.selectTab(this.activeTab);
		}

	},

	/**
	 * Affiche un onglet
	 */
	selectTab: function(iSec) {
		this.tabs[this.activeTab].className = '';
		this.tabs[iSec].className = 'actif';

		this.toggleTab(this.activeTab, true);
		this.toggleTab(iSec, false);

		this.activeTab = iSec;

		// Met à jour l'onglet actif à partir du champ caché
		if (this.html.input) this.html.input.value = this.activeTab;
	},

	/**
	 * Bascule une section
	 */
	toggleTab: function(iSec, bHide) {
	    var oParent = this.html.sectionArray[iSec].getParent().getParent();

	    if (! oParent) return;

	    if (bHide) {
	    	oParent.style.display = 'none';
		}
		else {
	    	oParent.style.display = 'block';
		}
	}

});

/**
 * Exécute l'action sélectionnée dans le selecteur d'actions
 * Le bouton associé au select doit avoir le même ID avec _btn en plus
 */
PsDossier.selectAction = function(strIDSel, aActions) {
	$(strIDSel + '_btn').addEvent('click', function() {

	    var oSelect = $(strIDSel);

	    if (oSelect && oSelect.selectedIndex) {
	    	var strCle = oSelect.options[oSelect.selectedIndex].value;

	    	var oActionInfo = aActions[strCle];

	    	if (oActionInfo.href) {
	    	    location.href = oActionInfo.href;
	    	}
	    	else if (oActionInfo.onclick) {
                // Restore the default selected option
                jQuery('#' + strIDSel + ' option').each(function() {
                    if (this.defaultSelected) {
                        this.selected = true;

                        if (Luceo.jsProperties && Luceo.jsProperties.upliftedUI) {
                            var styledSelect = jQuery('#' + strIDSel).parent('div.select-div').find(".select-styled");
                            var defaultText = jQuery('#' + strIDSel).children("option:selected").text();
                            styledSelect.find('span').text(defaultText);
                        }
                        return false;
                    }
                });

                oActionInfo.onclick();
	    	}
	    }
	});

	$(strIDSel + '_btn').addEvent('mouseover', function() {

	    var oSelect = $(strIDSel);

	    if (oSelect && oSelect.selectedIndex) {
	    	var strCle = oSelect.options[oSelect.selectedIndex].value;

	    	var oActionInfo = aActions[strCle];

	    	if (oActionInfo.onmouseover) {
	    	    // Rattache le this de la fonction au bouton
	    	    var boundFunction = oActionInfo.onmouseover.bind($(strIDSel + '_btn'));
	    	    boundFunction();
	    	}
	    }
	});
}

PsDossier.radioAction = function(button, radio, actions) {
    var button = $(button);

    if (button) {
        $(button).addEvent('click', function() {
            var key = $$('input[name=' + radio + ']:checked')[0].value;
            var action = actions[key];

            if (action.href) {
                location.href = action.href;
            } else if (action.onclick) {
                action.onclick();
            }
        });

        $(button).addEvent('mouseover', function() {
            var key = $$('input[name=' + radio + ']:checked')[0].value;
            var action = actions[key];

            if (action.onmouseover) {
	        var boundFunction = action.onmouseover.bind($(button));
	    	boundFunction();
            }
        });
    }
}
