/**
 * Versatile slider
 */
var PsVersatileSlider = new Class({
	/**
	 * Constructeur
	 */
	initialize: function(strID, oOptions) {
		this.id = strID;
		this.index = oOptions.boxIndex;
		this.increment = oOptions.boxWidth;
		this.boxValues = oOptions.boxValues;


		this.fx = new Fx.Tween($(this.id + '-list'), {duration: 500});

		// Fixe la taille des box
		var aLi = $(this.id + '-list').getElements('li');
		for(var iLi = 0; iLi < aLi.length; iLi++) {
			aLi[iLi].setStyles({
			    'width': oOptions.boxWidth + 'px',
			    'height': oOptions.boxHeight + 'px'
			});
		}

		// Position par défaut
		this.position(false);

		// Evenements
		var oThis = this;

	    $(this.id + '-previous').addEvent('click', function() {
			oThis.previousBox();
			if (oOptions.onChange) oOptions.onChange(oThis);
	        return false;
	    });

	    $(this.id + '-next').addEvent('click', function() {
	    	oThis.nextBox();
	    	if (oOptions.onChange) oOptions.onChange(oThis);
	        return false;
	    });
	},

	/**
	 * Avance d'une box
	 */
	nextBox: function() {
		if (this.index < this.boxValues.length - 1) {
		    this.index ++;
		    this.position(true);
		}
	},

	/**
	 * Recule d'une box
	 */
	previousBox: function() {
		if (this.index > 0) {
		    this.index --;
		    this.position(true);
		}
	},

	/**
	 * Positionne
	 * @param bool bAnimate Utilise une animation (transition)
	 */
	position: function(bAnimate) {
	    // Calcule l'incrément
	    var iTotalIncrement = -1 * this.index * this.increment;

	    if (this.fx) this.fx.pause();
	    if (bAnimate) {
	    	this.fx.start('margin-left', iTotalIncrement);
	    }
	    else {
	    	$(this.id + '-list').setStyle('margin-left', iTotalIncrement);
	    }

	    // Met à jour le champ caché
	    $(this.id).value = this.boxValues[this.index];

	    // Met à jour la visibilité des flèches
	    if (this.index < this.boxValues.length - 1) {
	    	$(this.id + '-next').setStyle('display', 'block');
	    }
	    else {
	    	$(this.id + '-next').setStyle('display', 'none');
	    }

	    // Met à jour la visibilité des flèches
	    if (this.index > 0) {
	    	$(this.id + '-previous').setStyle('display', 'block');
	    }
	    else {
	    	$(this.id + '-previous').setStyle('display', 'none');
	    }
	},

	/**
	 * Renvoie la valeur active
	 */
	getValue: function() {
		return this.boxValues[this.index];
	}
});
