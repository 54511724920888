/**
 * Interface Calendar86
 * Utilise calendar eightysix
 * http://dev.base86.com/scripts/mootools_javascript_datepicker_calendar_eightysix.html
 */

var PsCalendar86 = new Class({
	initialize: function(strContainerID, strBaseURL) {
	    /**
	     * Crée un système de date "custom" pour mootools
	     * pour utiliser le système d'i18n et en même temps notre système de locals
	     */
		MooTools.lang.set('custom', 'Date', {
			months: [PsLocal.JS_JANVIER, PsLocal.JS_FEVRIER, PsLocal.JS_MARS, PsLocal.JS_AVRIL, PsLocal.JS_MAI, PsLocal.JS_JUIN, PsLocal.JS_JUILLET, PsLocal.JS_AOUT, PsLocal.JS_SEPTEMBRE, PsLocal.JS_OCTOBRE, PsLocal.JS_NOVEMBRE, PsLocal.JS_DECEMBRE],
			days: [PsLocal.JS_DIMANCHE, PsLocal.JS_LUNDI, PsLocal.JS_MARDI, PsLocal.JS_MERCREDI, PsLocal.JS_JEUDI, PsLocal.JS_VENDREDI, PsLocal.JS_SAMEDI],
			dateOrder: ['date', 'month', 'year', '/']
		});
		MooTools.lang.setLanguage('custom');

		var calendar = new CalendarEightysix(strContainerID,{
			'theme': 'ps3',
			'injectInsideTarget': true,
			'alwaysShow': true,
			'pickable': true
		});

		calendar.addEvent('change', function(date) {
		    var strURL = strBaseURL;
		    strURL += '&date=' + date.format('%Y-%m-%d');
		    location.href = strURL;
		});

		//Render again because while initializing and doing the first render it did not have the event set yet
		calendar.render();
	}
});

