jQuery(document).ready(function() {
    if(!jQuery('.QuickApplyWebCheckList input').is(':checked')) {
        jQuery('#screenerWarning').addClass('is-invisible');
    }

    jQuery('.QuickApplyWebCheckList input').change(function () {
        jQuery('#screenerWarning').toggleClass('is-invisible');
    });

    quickApplyDialogHeight = 350;
    quickApplyDialogWidth= 500;

    jQuery("#QuickApplyDialog").dialog({
        autoOpen: false,
        height: quickApplyDialogHeight,
        width: quickApplyDialogWidth,
        resizable: false,
        buttons: {
            "Close": function(){
                jQuery(this).dialog('close');
            }
        }
    });
    jQuery(".ui-dialog-titlebar").hide();

    jQuery("#QuickApplyLearnMore").click(function(event){
        event.preventDefault();
        linkOffset = jQuery(this).position();
        scrolltop = jQuery(window).scrollTop();
        jQuery("#QuickApplyDialog").dialog("option", "position", [(linkOffset.left), linkOffset.top - scrolltop - quickApplyDialogHeight/2]);
        jQuery("#QuickApplyDialog").dialog('open');
    });
});

