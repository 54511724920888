/**
 * Classe PsWindowManager
 * Centralise la gestion des fenêtres javascript
 */
var PsWindowManager = new Class({
	// Constructeur
	initialize: function() {
		this.windows = new Array();		// Tableau des fenêtres
		this.nextIndex = 1;				// Prochain index disponible
	},
  
	// Enregistrement d'une fenêtre
	register: function(oNewWindow) {
		oNewWindow.index = this.nextIndex;
		this.nextIndex ++;

		// Ajoute au tableau
		this.windows[oNewWindow.index] = oNewWindow;
	},
	
	// Retourne une fenêtre existante à partir de son id
	// Ou null si n'existe pas déjà
	find: function(iId) {
		// Recherche si on n'a pas déjà une fenêtre avec cet ID
		// Si c'est le cas on la réactive simplement
		for(iIndex = 1; iIndex < this.windows.length; iIndex ++) {
			if (this.windows[iIndex].id == iId) {
				return this.windows[iIndex];
			}
		}
		return null;
	}
	
});

/**
 * IMplémentation du Desing Pattern Singleton
 */
PsWindowManager.instance = null;

PsWindowManager.getInstance = function() {
	if (PsWindowManager.instance == null) {
		PsWindowManager.instance = new PsWindowManager();
	}
	return PsWindowManager.instance;
}