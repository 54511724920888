/**
 * Classe PsWindow
 * Fenêtre javascript standard
 */
var PsDialog = new Class({
	Extends: PsWindow,
	
	/**
	* Constructeur
	* Attention, on n'a pas de vrai polymorphisme
	* On ne peut donc pas appeler le constructeur du parent et
	* s'attendre à ce qu'il appelle par exemple le bluid surchargé
	*
	* Options (en plus de celles de psWindow):
	* lockable  Si true, convertible en fenêtre
	*/
	initialize: function(strID, oContents, oOptions) {
		this.id = strID;			// identifiant unique

		// Enregistrement
		PsWindowManager.getInstance().register(this);

		// Initialisation effective de l'objet
	    this.initObject(strID, oContents, oOptions);

		// A la création de l'objet, on crée aussi la DIV elle même
		this.build();

		// Création effective
		if ($('MainForm')) {
			this.html.main.inject($('MainForm'), 'bottom');
		}
		else {
			this.html.main.inject(document.body, 'bottom');
		}

		// Ajoute les événements liés
		this.addEvents();

		// Execute
		this.execute(oContents);
	},

	// Initialisation effective de l'objet
	initObject: function(strID, oContents, oOptions) {
	    // Force un certain nombre d'options
	    oOptions.modal = true;

		// Constructeur du parent
		this.parent(strID, oContents, oOptions);
	},

	// Construit le rendu HTML
	build: function() {
	    // Création par défaut
	    this.parent();

	    // On remplace / ajoute maintenant le reste
	    this.html.main.className = 'content_popup';
	    this.html.window.className = 'popup is-popup';

	    this.html.minwidth.className = 'minwidth_popup';
	    this.html.top.className = 'top_pu';
     	if (! this.noframe) {
	    	this.html.closeImg.class = 'fa fa-times';
		}
		this.html.middle.className = 'middle_pu';
		this.html.bottom.className = 'btm_pu';
	}
});

/**
 * Ajout statique d'une fenêtre
 */
PsDialog.create = function(strID, oContents, oOptions) {
	return PsWindow.realCreate('PsDialog', strID, oContents, oOptions);
}