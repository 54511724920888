/**
 * Module WebMultiselect
 * Fonctions de support du WebMultiSelect
 * A transformer en classe avec génération automatique des événements
 */
 
 
/**
 * move
 */

function __wms_mv(l1,l2,limit,msg) {
	var i;

	for (i=0; i < l1.length; i++){
		if(l1.options[i].selected){
		    if (l2.length==limit && limit != 0) {
				alert(msg);
				return false;
			}
			var o = new Option(l1.options[i].text,l1.options[i].value);

		    var group = l1.options[i].get("data-group");
		    if (group) {
		        o.setAttribute("data-group", group)
                if( l2.getElementsByTagName("optgroup").length > 0 ) {
		            jQuery('optgroup[data-group^=' + group + ']').append(o);
		        } else {
		            l2.options[l2.options.length] = o;
		        }
		    } else {
		        l2.options[l2.options.length] = o;
		    }
		    l1.options[i]= null;
		    i--; // puisqu'on a supprimé une valeur, l'indice a diminué de 1
		}
	}
}

/**
 * moveAll
 */
function __wms_mva(l1,l2,limit,msg) {
	while (l1.options.length > 0) {

	    if (l2.length==limit && limit != 0) {
			alert(msg);
			return false;
		}

		o=new Option(l1.options[0].text,l1.options[0].value);
		l2.options[l2.options.length]=o;
		l1.options[0]=null;
	}
}

/**
 * up
 */
function __wms_u(liste) {
	var idx = liste.options.selectedIndex;
	if (idx>=1) {
		// echange avec l'élement juste au dessus
		o1=new Option(liste.options[idx].text,liste.options[idx].value);
		o2=new Option(liste.options[idx-1].text,liste.options[idx-1].value);
		liste.options[idx]=o2;
		liste.options[idx-1]=o1;
		// selectionne le nouvel indice
		liste.options.selectedIndex=idx-1;
	}
}

/**
 * down
 */
function __wms_d(liste) {
	var idx = liste.options.selectedIndex;
	if (idx < liste.options.length-1) {
		// echange avec l'élement juste au dessous
		o1=new Option(liste.options[idx].text,liste.options[idx].value);
		o2=new Option(liste.options[idx+1].text,liste.options[idx+1].value);
		liste.options[idx]=o2;
		liste.options[idx+1]=o1;
		// selectionne le nouvel indice
		liste.options.selectedIndex=idx+1;
	}
}

/**
 * syncValue
 */
function __wms_sync(strID, bAutoPostBack, strOnChange) {
	var i;
	var s = "";
	var oValue = $(strID);
	var oList = $(strID + "_right");
	for (i=0; i < oList.length; i++) {
		if (i > 0) s += ",";
			s +=  oList.options[i].value;
	}
	oValue.value = s;

	// Mécanisme d'autopostback
	if (bAutoPostBack) ta(strID);

	// Le OnChange
	if (strOnChange) eval(strOnChange);
}


/**
 * Add
 */
function __wms_a(strID, bAutoPostBack, strOnChange, iMax, strErrMsg) {
	__wms_mv($(strID + "_left"), $(strID + "_right"), iMax, strErrMsg);
	__wms_sync(strID, bAutoPostBack, strOnChange);
}

/**
 * Remove
 */
function __wms_r(strID, bAutoPostBack, strOnChange) {
	__wms_mv($(strID + "_right"), $(strID + "_left"));
	__wms_sync(strID, bAutoPostBack, strOnChange);
}

/**
 * addAll
 */
function __wms_aa(strID, bAutoPostBack, strOnChange, iMax, strErrMsg) {
	__wms_mva($(strID + "_left"), $(strID + "_right"), iMax, strErrMsg);
	__wms_sync(strID, bAutoPostBack, strOnChange);
}

/**
 * removeAll
 */
function __wms_ra(strID, bAutoPostBack, strOnChange) {
	__wms_mva($(strID + "_right"), $(strID + "_left"));
	__wms_sync(strID, bAutoPostBack, strOnChange);
}

/**
 * pressup
 */
function __wms_pu(strID, bAutoPostBack, strOnChange) {
	__wms_u($(strID + "_right"));
	__wms_sync(strID, bAutoPostBack, strOnChange);
}

/**
 * pressdown
 */
function __wms_pd(strID, bAutoPostBack, strOnChange) {
	__wms_d($(strID + "_right"));
	__wms_sync(strID, bAutoPostBack, strOnChange);
}
