/**
 * Classe psTriState
 * Gère une case à cocher 3 états
 */
var psTriState = new Class({
    /**
     * Constructeur
     * options:
     *      label: label to show for this checkbox
     *      checkValues: label to check for when determining a checkbox's state
     *      value: value to return when using "select all results"
     *      showMixState: Whether or not to show the tri state button (otherwise just uses on/off)
     *      countLabel: If showing counts, set this to the Label element to use
     *      countFormat: If showing counts, set this to the string format to use
     */
    initialize: function(oParent, aCheckboxes, onchange, options) {
        options = options || {};
        this.checkboxes = aCheckboxes;
        if (!this.checkboxes) {
            this.checkboxes = [new Element('input',{type:'checkbox'})];
        }
        this.onchange = onchange;
        this.label = options.label || '';
        this.strValueToCheck = options.checkValues;
        this.value = options.value;
        this.showMixState = (typeof options.showMixState == 'undefined') ? true : options.showMixState;
        this.countFormat = options.countFormat || '';
        this.countLabel = options.countLabel || null;
        this.totalCount = 0;
        this.linkedCheckbox = options.linkedCheckbox || null;

        var label = new Element('label', {text:this.label});
        label.inject(oParent, 'bottom');

        this.html = {
            none: null,
            mix: null,
            all: null
        };

        /**
         * Construit l'élément HTML
         */
        if ((Luceo.jsProperties && Luceo.jsProperties.upliftedUI)) {
            this.html.none = new Element('img', {
                'src':     Luceo.jsProperties.cdn + '/img/checkbox_unchecked.png',
                'title':   PsLocal.TXT_TOUT_COCHER,
                'alt':       PsLocal.TXT_TOUT_COCHER,
                'styles':   {
                    'display': 'none'
                }
            });
            this.html.none.inject(label, 'bottom');

            this.html.mix = new Element('img', {
                'src':     Luceo.jsProperties.cdn + '/img/checkbox_partial.png',
                'title':   PsLocal.TXT_TOUT_DECOCHER,
                'alt':       PsLocal.TXT_TOUT_DECOCHER,
                'styles':   {
                    'display': 'none'
                }
            });
            this.html.mix.inject(label, 'bottom');

            this.html.all = new Element('img', {
                'src':     Luceo.jsProperties.cdn + '/img/checkbox.png',
                'title':   PsLocal.TXT_TOUT_DECOCHER,
                'alt':       PsLocal.TXT_TOUT_DECOCHER,
                'styles':    {
                    'display': 'none'
                }
            });
            this.html.all.inject(label, 'bottom');
        } else {
            this.html.none = new Element('img', {
                'src':     Luceo.jsProperties.cdn + '/img/check_none.gif',
                'title':   PsLocal.TXT_TOUT_COCHER,
                'alt':       PsLocal.TXT_TOUT_COCHER,
                'styles':   {
                    'display': 'none'
                }
            });
            this.html.none.inject(label, 'bottom');

            this.html.mix = new Element('img', {
                'src':     Luceo.jsProperties.cdn + '/img/check_mix.gif',
                'title':   PsLocal.TXT_TOUT_DECOCHER,
                'alt':       PsLocal.TXT_TOUT_DECOCHER,
                'styles':   {
                    'display': 'none'
                }
            });
            this.html.mix.inject(label, 'bottom');

            this.html.all = new Element('img', {
                'src':     Luceo.jsProperties.cdn + '/img/check_all.gif',
                'title':   PsLocal.TXT_TOUT_DECOCHER,
                'alt':       PsLocal.TXT_TOUT_DECOCHER,
                'styles':    {
                    'display': 'none'
                }
            });
            this.html.all.inject(label, 'bottom');
        }
        if (this.countLabel) {
            this.countLabel.inject(oParent, 'bottom');
        }

        this.addEvents();
    },

    /**
     * Attache les événements
     */
    addEvents: function() {
        var oCtrl = this;

        var iNbCheckBoxes = this.checkboxes.length;
        for (var iCheckBox = 0; iCheckBox < iNbCheckBoxes;  iCheckBox ++) {
            if (this.strValueToCheck){
                if (this.checkboxes[iCheckBox].type == 'checkbox' && this.checkboxes[iCheckBox].className != 'disabled' && this.checkboxes[iCheckBox].value == this.strValueToCheck) {
                    $(this.checkboxes[iCheckBox]).addEvent('change', function(){
                        oCtrl.updateStatus();
                    });
                }
            } else{
                if (this.checkboxes[iCheckBox].type == 'checkbox' && this.checkboxes[iCheckBox].className != 'disabled') {
                    $(this.checkboxes[iCheckBox]).addEvent('change', function(){
                        oCtrl.updateStatus();
                    });
                }
            }
        }

        this.html.none.addEvent('click', function(){oCtrl.checkAll();});
        this.html.mix.addEvent('click', function(){oCtrl.uncheckAll();});
        this.html.all.addEvent('click', function(){oCtrl.uncheckAll();});

        // Au chargement, met à jour le statut
        window.addEvent('domready', function(){
            oCtrl.updateStatus();
        });
    },

    /**
     * Vérifie le statut du bouton et le met à jour
     */
    updateStatus: function(sendEvent) {
        var fireEvent = true;
        if (typeof sendEvent != 'undefined') {
            fireEvent = sendEvent;
        }
        var iNbCheck = 0;
        var iNbUnCheck = 0;

        // Boucle sur les input pour récupérer leur état d'activation
        var iNbCheckBoxes = this.checkboxes.length;
        for (var iCheckBox = 0; iCheckBox < iNbCheckBoxes;   iCheckBox ++) {
            if (this.strValueToCheck){
                if (this.checkboxes[iCheckBox].type == 'checkbox' && this.checkboxes[iCheckBox].className != 'disabled' && this.checkboxes[iCheckBox].value == this.strValueToCheck) {
                    if (this.checkboxes[iCheckBox].checked) {
                        iNbCheck ++;
                    }
                    else {
                        iNbUnCheck ++;
                    }
                }
            } else{
                if (this.checkboxes[iCheckBox].type == 'checkbox' && this.checkboxes[iCheckBox].className != 'disabled') {
                    if (this.checkboxes[iCheckBox].checked) {
                        iNbCheck ++;
                    }
                    else {
                        iNbUnCheck ++;
                    }
                }
            }
        }

        this.totalCount = iNbCheck;

        // Case à cocher différente selon les cas
        if (iNbUnCheck == 0 && iNbCheck != 0) {
            // Toutes les cases cochées
            this.html.all.style.display = 'inline';
            this.html.none.style.display = 'none';
            this.html.mix.style.display = 'none';
        }
        else if (iNbCheck == 0 || !this.showMixState) {
            // Aucune case cochée
            this.html.all.style.display = 'none';
            this.html.none.style.display = 'inline';
            this.html.mix.style.display = 'none';
        }
        else {
            // Certaines cases cochées mais pas toutes
            this.html.all.style.display = 'none';
            this.html.none.style.display = 'none';
            this.html.mix.style.display = 'inline';
        }

        if (this.onchange && fireEvent) this.onchange();

        if (this.countLabel) {
            var count = this.totalCount;
            if (this.value && iNbCheck == 1) {
                // the count comes from the # of elements in value
                count = this.value;
            } else {
                // otherwise, the count comes from the actual number checked
                // if there is a linked checkbox, use that, otherwise use the real count
                if (this.linkedCheckbox) {
                    count = this.linkedCheckbox.getCount();
                } else {
                    // already handled, the count is just this.totalCount
                }
            }

            this.countLabel.set('text', sprintf(this.countFormat, count));
        }
    },

    /**
     * Coche tout
     */
    checkAll: function() {
        this.toggleAll(true);
    },

    /**
     * Décoche tout
     */
    uncheckAll: function() {
        this.toggleAll(false);
    },

    /**
     * Bascule tout
     */
    toggleAll: function(bCheck, sendEvent) {
        var fireEvent = true;
        if (typeof sendEvent != 'undefined') {
            fireEvent = sendEvent;
        }

        var iNbCheckBoxes = this.checkboxes.length;
        for (var iCheckBox = 0; iCheckBox < iNbCheckBoxes;   iCheckBox ++) {
            if (this.strValueToCheck){
                if (this.checkboxes[iCheckBox].type == 'checkbox' && this.checkboxes[iCheckBox].className != 'disabled' && this.checkboxes[iCheckBox].value == this.strValueToCheck) {
                    this.checkboxes[iCheckBox].checked = bCheck;
                }
            } else{
                if (this.checkboxes[iCheckBox].type == 'checkbox' && this.checkboxes[iCheckBox].className != 'disabled') {
                    this.checkboxes[iCheckBox].checked = bCheck;
                }
            }
        }

        this.updateStatus(fireEvent);
    },

    /**
     * Returns true if and only if the "all" checkbox is displayed.
     */
    isChecked: function() {
        return this.html.all.style.display != 'none';
    },

    getValue: function() {
        return this.value;
    },
    getCount: function() {
        return this.totalCount;
    }
});
