/**
 * Cache ajax
 */
var psAjaxCache = new Class({
	/**
	 * Constructeur
	 */
	initialize: function() {
    	this.cache = new Hash();
	},

	/**
	 * Ajout d'un controle
	 */
	set: function(oOptions, oResponse) {
		this.cache.set(this.generateKey(oOptions), oResponse);
	},

	/**
	 * Ajout d'un controle
	 */
	get: function(oOptions) {
		return this.cache.get(this.generateKey(oOptions));
	},
  
	/**
	 * Génération de la clé
     */
	generateKey: function(oOptions) {
   		var strKey = JSON.encode(oOptions);
		return Base64.encode(strKey);
	}

});

/**
 * IMplémentation du Desing Pattern Singleton
 */
psAjaxCache.instance = null;

psAjaxCache.getInstance = function() {
	if (psAjaxCache.instance == null) {
		psAjaxCache.instance = new psAjaxCache();
	}
	return psAjaxCache.instance;
}