/**
 * Fenêtre de message
 */
var PsMsg = new Class({
	/**
	 * Constructeur
	 */
	initialize: function(strMsg, oParent) {
		positionOptions = {};
	    if (oParent) {
	    	positionOptions.relativeTo = oParent;
	    	positionOptions.position = 'bottomRight';
	    }
	    else {
	    	positionOptions.position = 'upperRight';
	    }

		new Confirmer({
		    msg: strMsg,
			positionOptions: positionOptions,
			prompterStyle:{
			    color: '#fff',
				backgroundColor: 'green',
				border: '1px solid #fff'
			}
		}).prompt();
	}
});