/**
 * Classe WebTree
 * Fonctions de support du WebTree
 */
var WebTree = new Class({
	initialize: function() {
	}
});

WebTree.getStatus = function(strID) {
	var strStatus = $(strID).value;
	strStatus = Base64.decode(strStatus);

	var oStatus = {};
	if (strStatus) {
		oStatus = JSON.decode(strStatus);
	}
	
	return oStatus;
}

WebTree.setStatus = function(strID, oStatus) {
	var strStatus = JSON.encode(oStatus);
	strStatus = Base64.encode(strStatus);
	$(strID).value = strStatus;
}

WebTree.check = function(strID, node, bCheck) {
	var oStatus = WebTree.getStatus(strID);
	if (! $defined(oStatus.checked)) oStatus.checked = [];
	if (bCheck) {
		oStatus.checked.push(node.property.id.replace(strID + '_nd', ''));
	}
	else {
		oStatus.checked.erase(node.property.id.replace(strID + '_nd', ''));
	}
	WebTree.setStatus(strID, oStatus);
}

WebTree.toggle = function(strID, node, bOpen) {
	var oStatus = WebTree.getStatus(strID);
	if (! $defined(oStatus.opened)) oStatus.opened = [];
	if (bOpen) {
		oStatus.opened.push(node.property.id.replace(strID + '_nd', ''));
	}
	else {
		oStatus.opened.erase(node.property.id.replace(strID + '_nd', ''));
	}
	WebTree.setStatus(strID, oStatus);
}
