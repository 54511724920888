/**
 * Classe WebSelector
 * Support javascript du WebSelector
 */
var WebSelector = new Class({

    initialize: function(strID) {

        this.id = strID;
        this.filled = false;

        // Elements
        this.html = {};
        this.html.container = jQuery('#' + this.id + '-container');
        this.html.clear = jQuery('.action-close', this.html.container);
        this.html.loading = jQuery('.loading', this.html.container);
        this.html.select = jQuery('.list', this.html.container);
        this.html.hidden = jQuery('.hidden', this.html.container);

        // Chosen options
        this.chosenOptions = {};
        this.chosenOptions.max_selected_options  = this.html.select.data('max_nb') || 'Infinity';
        this.chosenOptions.max_selected_text    = this.html.select.data('max_text') || null;        // Not currently used
        this.chosenOptions.search_contains = true;
        this.chosenOptions.single_backstroke_delete = false;

        // Other options
        this.onChange = this.html.select.data('onchange') || null;
        this.autoPostback = this.html.select.data('autopostback') || null;

        // Values
        this.selected = this.importSelected();
        this.available = {};
    },

    init: function() {
        var that = this;

        this.html.select.chosen(this.chosenOptions);
        this.html.select.trigger('chosen:updated');

        this.hideLoading();

        this.html.clear
            .on('click', function(){
                that.clearSelect();
            });

        this.html.select
            .on('change', function(){
                WCManager.getInstance().eventsDisabled = true;
                that.exportSelected();

                if (that.autoPostback) {
                    ta(this.id);
                }

                if (that.onChange) {
                    eval(that.onChange);
                }
                WCManager.getInstance().eventsDisabled = false;

            });

        this.html.container
            .mouseover(function(){
                if( !that.filled && !WCManager.getInstance().eventsDisabled ) {
                    that.loadValues();
                }
            })
    },

    loadValues : function () {
        this.showLoading();
        WCManager.getInstance().eventsDisabled=false;
        this.filled = true;
        PsAjax.remoteMethod('WebSelector::getListeAjax', [this.id], {targetObject: this});
    },

    fill: function (data) {
        var that = this;
        var array = JSON.decode(data);

        // Clear available array
        this.available = {};

        // Fill available array
        jQuery.each(array, function (i, j){
            that.available[i] = j;
        });

        this.hideLoading();
        this.updateSelect();
    },

    showLoading: function(){
        this.html.loading.show();
    },

    hideLoading: function(){
        this.html.loading.hide();
    },

    exportSelected : function (){
        var values = [];
        var elements = jQuery('option:selected', this.html.select);
        jQuery.each(elements, function(i, element){
            values.push(jQuery(element).val());
        });
        this.html.hidden.val(values.join(','));
    },

    importSelected : function (){
        return this.html.hidden.val().split(',');
    },

    updateSelect : function (){
        var that = this;

        jQuery.each(this.available, function (i, j){
            var option = jQuery('<option />')
                .val(j[0])

            if(j[1]){
                option.text(j[1]);
            } else {
                option.text('(null)');
            }

            that.html.select.append(option);
        });

        // Trigger Chosen to reflect changes
        this.html.select.trigger('chosen:updated');

    },

    // Clear select
    clearSelect: function () {
        jQuery('option', this.html.select).prop("selected", false);

        this.html.select.trigger('change');
        this.html.select.trigger('chosen:updated');

    },

    // Automatic treatment of ajax result
    onAjaxLoaded: function (strData) {
        this.fill(strData);
    },

    onReload : function() {
        this.loadValues();
    }

});
