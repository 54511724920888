/**
 * Module WebTextArea
 * Fonctions de support du WebTextArea
 * A transformer en classe
 */

// Script d'affichage de la popup d'édition
function __WebMLText_openEditPopup(strID, strRootURL, strParams, iEBWidth, iEBHeight) {
	window.open(strRootURL + "lib/translator.php" + strParams,
	"translator",
	"scrollbars=yes,resizable=yes,width=" + iEBWidth + ",height=" + iEBHeight + ",left=100,top=100,modal=yes");
}
function textAreaAdjust(o) {
  o.style.height = "34px";
  if(o.scrollHeight > 34) {
    o.style.height = (25+o.scrollHeight)+"px";
  }
}
