/**
 * Module Page
 * Regroupe toutes les méthodes liées au mécanisme de page
 */
var _psViewInfo = {};


// Code appelé à la soumission du formulaire
function MainForm_onSubmit() {
	// Place la position de la page dans le champ __Scroll
	$('__Scroll').value = window.getScroll().y;
}

// Sélection du champ actif
function selectActiveField(strField) {
	if (strField) {
		var oCtrl = $(strField);

		if (oCtrl) {
		  	if (oCtrl.type && oCtrl.type != 'hidden') {
			  oCtrl.focus();
			}
		}
	}
}

// Alias de PsAjax.throwAction pour alleger les pages
function ta(strAction, strParam, strViewID, strAjax, bDoNotSubmit, bNoWaitLoader, waitLoaderLabel) {
	PsAjax.throwAction(strAction, strParam, strViewID, strAjax, bDoNotSubmit,  bNoWaitLoader, waitLoaderLabel);
}
