/*
tracks the simple quicksearch that appears on every page, except bureau.php
 */
Luceo = window.Luceo || {};
Luceo.Quicksearch = Luceo.Quicksearch || {};

(function ($) {

    var MAX_RADIUS_DEFAULT = '50';

    var txtFields = {
        keywords: '#blsearchtxtSearch',
        location: '#blsearchtxtGeoSearch'

    };
    var selectFields = {
        distanceMaxRadius: '#blsearchtxtMaxRadius'
    };

    var radioFields = {
        fullTextSearch: '#blsearchoptEmpoweredSearch\\:boolean',
        fullAndSemanticSearch: '#blsearchoptEmpoweredSearch\\:empowered'
    };

    var searchedFieldsCount = 0;
    var allSearchedFields = [];

    function adjustTotals(key) {
        searchedFieldsCount++;
        allSearchedFields.push(key);
    }


    function searchedFields() {
        $("#blsearchbtnSearch").on("click", function () {

            Luceo.GaShared.eachAttribute(txtFields, function (key, value) {
                if (typeof $(value).attr('value') !== typeof undefined &&
                    $(value).attr('value') !== '' && $(value).attr('value') !== 'MM/DD/YYYY') {
                    adjustTotals(key);
                }
            });

            Luceo.GaShared.eachAttribute(radioFields, function (key, value) {
                if ($(value) !== typeof undefined &&
                    $(value).is(':checked')) {
                    adjustTotals(key);
                }
            });

            Luceo.GaShared.eachAttribute(selectFields, function (key, value) {
                if ($(value).val() !== typeof undefined &&
                    $(value).val() !== MAX_RADIUS_DEFAULT) {
                    adjustTotals(key);
                }
            });

            if (searchedFieldsCount > 0) {
                Luceo.GaShared.logEvent({
                    event: 'candidateQuickSearchSummary',
                    searchFieldSummary: allSearchedFields.join(' - '),
                    searchFieldCount: searchedFieldsCount
                });
            }
        });
    }
    Luceo.Quicksearch.searchedFields = searchedFields;
})(jQuery);

jQuery(document).ready(function(){
    Luceo.Quicksearch.searchedFields();
});

