/**
 * Compatibilité du menu avec IE6
 */
menuHover = function() {
	var aMenus = $$('.menu');

	for (var iMenu = 0; iMenu < aMenus.length; iMenu ++) {
		var aEls = aMenus[iMenu].getElements('li');
		for (var i=0; i<aEls.length; i++) {
			menuItemHover(aEls[i]);
		}
	}
}

menuItemHover = function(oItem) {
	oItem.addEvent('mouseover', function() {
		oItem.className += ' menuhover';
	});

	oItem.addEvent('mouseout', function() {
		oItem.className = oItem.className.replace(new RegExp(' menuhover\\b'), '');
	});
}

if (window.attachEvent) window.attachEvent('onload', menuHover);
