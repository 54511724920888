/**
 * Fonctions diverses
 */

// Bascule une section de formulaire
function __frm_s_t(strID, oImg, strDisplay) {
  // Récupère la section
  var oElem = $(strID);
  // Récupère le champ caché
  var oHid = $(strID + '_h');

  if (strID) {
    if (oHid.value == 1) {
      if (strDisplay && navigator.appName == 'Netscape') {
        oElem.style.display = strDisplay;
      }
      else {
        oElem.style.display = 'block';
      }
      if(oImg.tagName !== 'I') {
        oImg.src = Luceo.jsProperties.cdn + '/img/pictos/ico_moins.gif';
      } else {
        oImg.className = "fa fa-minus-square fa-1";
      }
      oHid.value = 0;
    }
    else {
      oElem.style.display = 'none';
      if(oImg.tagName !== 'I') {
        oImg.src = Luceo.jsProperties.cdn + '/img/pictos/ico_plus.gif';
      } else {
        oImg.className = "fa fa-plus-square fa-1";
      }

      oHid.value = 1;
    }
  }
}

// le fameux showvar mais en js
function showvar(obj) {
  win_print_r = window.open('about:blank', 'win_print_r');
  win_print_r.document.write('<html><body>');
  r_print_r(obj, win_print_r);
  win_print_r.document.write('</body></html>');
 }

function r_print_r(theObj, win_print_r) {
  if(theObj.constructor == Array ||
   theObj.constructor == Object){
   if (win_print_r == null)
    win_print_r = window.open('about:blank', 'win_print_r');
   }
   for(var p in theObj){
    if(theObj[p].constructor == Array||
     theObj[p].constructor == Object){
     win_print_r.document.write("<li>["+p+"] =>"+typeof(theObj)+"</li>");
     win_print_r.document.write("<ul>")
     r_print_r(theObj[p], win_print_r);
     win_print_r.document.write("</ul>")
    } else {
     win_print_r.document.write("<li>["+p+"] =>"+theObj[p]+"</li>");
    }
   }
  win_print_r.document.write("</ul>")
 }

// ###########################
// ###     CB Network     ####
// ###########################

(function (CBNetwork) {

    // Gets the checked location checkboxes of CBOne recommendation
    CBNetwork.getCheckedLocationsArray = function() {
        return jQuery("#locationList input:checkbox:checked").map(function() {
            return jQuery(this).val();
        }).get();
    },

    // Gets the checked site checkboxes of CBOne recommendation
    CBNetwork.getCheckedSitesArray = function() {
        return jQuery("#recSites input:checkbox:checked").map(function() {
            return jQuery(this).val();
        }).get();
    },

    // Updates the sites * locations counter
    CBNetwork.updateCountPanels = function() {
        jQuery("#pnlSites").html(CBNetwork.getCheckedSitesArray().length);
        jQuery("#pnlLocations").html(CBNetwork.getCheckedLocationsArray().length);
        jQuery("#pnlPostings").html(CBNetwork.getCheckedLocationsArray().length * CBNetwork.getCheckedSitesArray().length);
    }
})(CBNetwork = window.CBNetwork || {});

// Triggers the count updater when a location CBOne recommendation checkbox is ticked
jQuery(document).on('click', '#locationList input[type=checkbox], #recSites input[type=checkbox]', function(){
    CBNetwork.updateCountPanels();
});

// Send migrate warnings as events to google analytics
window.setInterval(function() {
    var _gaq = _gaq || [];

    if (jQuery.migrateWarnings.length > 0) {
        jQuery.migrateWarnings.forEach(function(msg){
            _gaq.push(['_trackEvent', 'jQuery Migrate Warnings', msg, document.URL, true]);
        });
        jQuery.migrateReset();
    }
}, 5000);

// focus and highlight any text or textarea fields marked with the class autofocus
jQuery(document).on('click', 'input[type=text].autofocus, textarea.autofocus', function() {
    jQuery(this).focus().select();
});
