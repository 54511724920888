/**
 * Tabswapper bureau
 * @version 3.0
 */
var PsTabswapperBureau = new Class({
	/**
	 * Constructeur
	 */
	initialize: function(strID) {
	    this.id = strID;

	    this.html = {
	        root: $(this.id),
	        tabs: null,
	        sections: null
		};

		if (! this.html.root) return;
		
		/**
		 * Retrouve les éléments html
		 */
		this.html.tabs = this.html.root.getElements('li');
		this.html.sections = this.html.root.getElements('div.panelSet div.panelBlock');

		new TabSwapper({
			selectedClass: 'on',
			deselectedClass: 'off',
			tabs: this.html.tabs,
			sections: this.html.sections,
//			smooth: true,
//			smoothSize: true,
			rearrangeDOM: false
		});
	}
});
